import { useState, useEffect } from 'react';
import Button from 'components/base/Button';
import { withTheme } from 'react-jsonschema-form';
import LayoutField from 'react-jsonschema-form-layout-2';
import Bootstrap4Theme from 'react-jsonschema-form-bs4';
import { Col, Row, Alert } from 'react-bootstrap';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import LoadingOverlay from 'components/loading-overlay/LoadingOverlay';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { patchItemStock, getItem, getItemStocks } from 'api/api';

function EditStockWeapon() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const Form = withTheme(Bootstrap4Theme);
  const [formData, setFormData] = useState();
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState(false);
  const [itemName, setItemName] = useState(null);
  const { id, stockId } = useParams();
  const customState = state && state.customState;

  let breadcrumbItems = [];
  if (customState === 'stockItem') {
    breadcrumbItems = [
      {
        label: 'Weapon',
        url: `/weapons/weapons`
      },
      {
        label: 'Weapon Stocks',
        url: `/weapons/stock-weapon/${id}`
      },
      {
        label: 'Update Stock',
        active: true
      }
    ];
  } else if (customState === 'itemDetails') {
    breadcrumbItems = [
      {
        label: 'Weapon Details',
        url: `/weapons/weapon-details/${id}`
      },
      {
        label: 'Update Stock',
        active: true
      }
    ];
  }

  useEffect(() => {
    loadItem();
    loadStock();
  }, []);

  const loadItem = () => {
    setLoading(true);

    getItem(id)
      .then(async response => {
        const data = response.data.data;
        setItemName(data.name);
      })
      .catch(err => {
        try {
          setError(err.response.data.errors);
        } catch (ex) {
          setError(['Please try again later...']);
        }
      });

    setTimeout(function () {
      setLoading(false);
    }, 1000);
  };

  const loadStock = () => {
    setError([]);
    setLoading(true);

    getItemStocks(id)
      .then(response => {
        const stockArray = response.data.data.filter(
          c => c.id === parseInt(stockId)
        );
        if (stockArray.length > 0) {
          setFormData({
            epc: stockArray[0].epc,
            serial_number: stockArray[0].serial_number,
            cfrn: stockArray[0].cfrn,
            tid: stockArray[0].tid
          });
        }
      })

      .catch(err => {
        try {
          setError(err.response.data.errors);
        } catch (ex) {
          setError(['Please try again later...']);
        }
      });

    setTimeout(function () {
      setLoading(false);
    }, 1000);
  };

  const handleSubmit = ({ formData }) => {
    setError([]);
    setFormData(formData);
    setLoading(true);

    patchItemStock(id, stockId, formData)
      .then(response => {
        if (response.status === 200) {
          if (customState === 'stockItem')
            navigate('/weapons/stock-weapon/' + id);
          if (customState === 'itemDetails')
            navigate('/weapons/weapon-details/' + id);
        }
      })
      .catch(err => {
        console.log(err);
        try {
          setError(err.response.data.errors);
        } catch (error) {
          setError(['Please try again later...']);
        }
      });

    setTimeout(function () {
      setLoading(false);
    }, 1000);
  };

  const schema = {
    type: 'object',
    required: ['epc', 'serial_number'],
    properties: {
      epc: {
        title: 'EPC',
        type: 'string'
      },
      serial_number: {
        title: 'Serial Number',
        type: 'string'
      },
      cfrn: {
        title: 'Canadian Firearms Reg NR',
        type: 'string'
      },
      tid: {
        title: 'TID',
        type: 'string'
      }
    }
  };

  const uiSchema = {
    'ui:field': 'layout',
    name: {
      'ui:autofocus': true
    },
    description: {
      'ui:widget': 'textarea'
    },
    'ui:layout': [
      { epc: { md: 8 } },
      { serial_number: { md: 8 } },
      { cfrn: { md: 8 } },
      { tid: { md: 8 } }
    ]
  };

  const fields = {
    layout: LayoutField
  };

  const handleGoBack = () => {
    if (customState === 'stockItem') navigate('/weapons/stock-weapon/' + id);
    if (customState === 'itemDetails')
      navigate('/weapons/weapon-details/' + id);
  };

  return (
    <div>
      <PageBreadcrumb items={breadcrumbItems} />
      <div className="mb-9">
        <div className="d-flex flex-wrap gap-3 flex-between-end mb-5">
          <div>
            <h2 className="mb-2">{`Edit Stock for '${itemName}'`}</h2>
            <h5 className="text-700 fw-semi-bold">Edit stock</h5>
          </div>
        </div>
        <Row className="g-5">
          <Col xs={12} xl={8}>
            <div>
              <div>
                {Object.entries(error).length > 0 &&
                  error.map((e, index) => (
                    <Alert variant="soft-danger" key={index}>
                      {e}
                    </Alert>
                  ))}
              </div>

              {loading && <LoadingOverlay />}

              <Form
                schema={schema}
                uiSchema={uiSchema}
                fields={fields}
                onSubmit={handleSubmit}
                formData={formData}
                showErrorList={false}
              >
                <div
                  className="d-flex flex-wrap gap-2"
                  style={{ marginTop: '10px' }}
                >
                  <Button variant="primary" type="submit">
                    Submit
                  </Button>
                  <Button
                    variant="phoenix-secondary"
                    type="button"
                    onClick={() => handleGoBack()}
                  >
                    Discard
                  </Button>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default EditStockWeapon;
