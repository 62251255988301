import { useEffect, useState } from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { useNavigate, Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationTotalStandalone,
  PaginationProvider,
  PaginationListStandalone
} from 'react-bootstrap-table2-paginator';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Alert, Spinner } from 'react-bootstrap';
import Badge from 'components/base/Badge';
import FilterTab from 'components/common/FilterTab';
import moment from 'moment';
import useAdvanceTable from 'hooks/useAdvanceTable';
import FeatherIcon from 'feather-icons-react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CustomSortCaret } from 'helpers/ui-utils';
import { getOrders } from 'api/api';
import DownloadButton from 'helpers/orders-download-utils';

const Orders = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [error, setError] = useState([]);
  const [total, setTotal] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [sortColumn, setSortColumn] = useState('asc');
  const [columnName, setColumnName] = useState('id');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [clearButtonVisibility, setClearButtonVisibility] = useState(false);
  const [downLoadData, setDownLoadData] = useState([]);
  const [sortString, setSortString] = useState('id:desc');

  useEffect(() => {
    loadData(currentPage, pageSize, 'id:desc', null, null);
  }, []);

  useEffect(() => {
    loadDownLoadData(null, null);
  }, []);

  const loadData = (pageNr, pageSize, sortBy, fromDate, toDate) => {
    setError([]);
    setLoading(true);
    setData([]);
    getOrders(pageNr, pageSize, sortBy, fromDate, toDate)
      .then(response => {
        console.log(response);
        setTotal(response.data.data.total);
        if (response.data.data.data.length > 0) {
          const flattenedData = response.data.data.data.map(item => ({
            id: item.id,
            item_id: item.item.id,
            name: item.item.name,
            sku: item.item.sku,
            description: item.item.description,
            category:
              item.item.category === undefined ? '' : item.item.category.name,
            added_user: `${item.added_user.first_name} ${item.added_user.last_name}`,
            added_time:
              item.added_time === null
                ? ''
                : moment(item.added_time).format('MM-DD-YYYY HH:mm'),
            status: item.status.name,
            location: item.item.container.location.name,
            serial_number: item.item_stock.serial_number,
            image: item.item.image
          }));

          setData(flattenedData);
        }
      })
      .catch(err => {
        console.log(err.response);
        try {
          if (err.response.status === 401 || err.response.status === 400) {
            navigate('/sign-in');
          }
          setError(err.response.data.errors);
        } catch (ex) {
          console.log(ex);
          setError(['Please try again later...']);
        }
      });

    setTimeout(function () {
      setLoading(false);
    }, 1000);
  };

  const loadDownLoadData = (fromDate, toDate) => {
    setError([]);
    getOrders(1, 0, 'id:desc', fromDate, toDate)
      .then(response => {
        if (response.data.data.data.length > 0) {
          const flattenedData = response.data.data.data.map(item => ({
            make: item.item.name,
            model: item.item.description,
            serial_number: item.item_stock.serial_number,
            calibre: item.item.sku,
            location: item.item.container.location.name,
            user: `${item.added_user.first_name} ${item.added_user.last_name}`,
            date:
              item.added_time === null
                ? ''
                : moment(item.added_time).format('MM-DD-YYYY HH:mm'),
            status: item.status.name
          }));

          setDownLoadData(flattenedData);
        }
      })
      .catch(err => {
        try {
          if (err.response.status === 401 || err.response.status === 400) {
            navigate('/sign-in');
          }
          setError(err.response.data.errors);
        } catch (ex) {
          console.log(ex);
          setError(['Please try again later...']);
        }
      });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const CustomSortCaretUpdatedByOrder = (order, dataField) => {
    if (columnName === 'id')
      return (
        <span className="custom-sort-cart">
          <div style={{ display: 'inline-block' }}>
            ▲
            <br />▼
          </div>
        </span>
      );
    else if (sortColumn === 'asc')
      return (
        <span className="custom-sort-cart">
          <div style={{ display: 'inline-block' }}>
            <div style={{ display: 'inline-block' }}> ▲ </div>
            <br />
            <div style={{ display: 'inline-block', visibility: 'collapse' }}>
              ▼
            </div>
          </div>
        </span>
      );
    else if (sortColumn === 'desc')
      return <span className="custom-sort-cart">▼</span>;
    return null;
  };

  const columns = [
    {
      dataField: 'image',
      formatter: (cellContent, row) => (
        <Link
          to={`/weapons/weapon-details/${row.item_id}`}
          className="rounded-2 border d-inline-block"
        >
          <img src={row.image} alt="" width={53} />
        </Link>
      ),
      headerStyle: () => ({ width: '80px' })
    },
    {
      dataField: 'name',
      text: 'MAKE',
      headerStyle: { width: '10%', fontSize: '13px' },
      sort: true,
      sortCaret:
        columnName === 'name' ? CustomSortCaretUpdatedByOrder : CustomSortCaret,
      style: {
        fontSize: '12px',
        fontWeight: 'bold',
        color: 'phoenix-gray-1100'
      },
      formatter: (cell, row) => {
        return (
          <>
            <Link
              to={`/weapons/weapon-details/${row.item_id}`}
              className="fw-semi-bold line-clamp-3"
            >
              {row.name}
            </Link>
          </>
        );
      }
    },

    {
      dataField: 'description',
      text: 'MODEL',
      headerStyle: { width: '10%', fontSize: '13px' },
      sort: true,
      sortCaret:
        columnName === 'description'
          ? CustomSortCaretUpdatedByOrder
          : CustomSortCaret,
      style: {
        fontSize: '12px',
        color: 'phoenix-gray-1100',
        fontWeight: 'bold'
      },
      formatter: (cell, row) => {
        return <> {row.description}</>;
      }
    },

    {
      dataField: 'serial_number',
      text: 'SERIAL NR',
      headerStyle: { width: '10%', fontSize: '13px' },
      sort: true,
      sortCaret:
        columnName === 'serial_number'
          ? CustomSortCaretUpdatedByOrder
          : CustomSortCaret,
      style: {
        fontSize: '12px',
        color: 'phoenix-gray-1100',
        fontWeight: 'bold'
      },
      formatter: (cell, row) => {
        return <>{row.serial_number}</>;
      }
    },

    {
      dataField: 'sku',
      text: 'CALIBRE',
      headerStyle: { width: '10%', fontSize: '13px' },
      sort: true,
      sortCaret:
        columnName === 'sku' ? CustomSortCaretUpdatedByOrder : CustomSortCaret,
      style: {
        fontSize: '12px',
        fontWeight: 'bold',
        color: 'phoenix-gray-1100'
      },
      formatter: (cell, row) => {
        return <>{row.sku}</>;
      }
    },

    {
      dataField: 'location',
      text: 'LOCATION',
      headerStyle: { width: '15%', fontSize: '13px' },
      sort: true,
      sortCaret:
        columnName === 'location'
          ? CustomSortCaretUpdatedByOrder
          : CustomSortCaret,
      style: {
        fontSize: '12px',
        fontWeight: 'bold',
        color: 'phoenix-gray-1100'
      },
      formatter: (cell, row) => {
        return <>{row.location}</>;
      }
    },
    {
      dataField: 'added_user',
      text: 'USER',
      headerStyle: { width: '15%', fontSize: '13px' },
      sort: true,
      sortCaret:
        columnName === 'added_user'
          ? CustomSortCaretUpdatedByOrder
          : CustomSortCaret,
      style: {
        fontSize: '12px',
        color: 'phoenix-gray-1100'
      },
      formatter: (cell, row) => {
        return <>{row.added_user}</>;
      }
    },
    {
      dataField: 'added_time',
      text: 'DATE',
      headerStyle: { width: '15%', fontSize: '13px' },
      sort: true,
      sortCaret:
        columnName === 'added_time'
          ? CustomSortCaretUpdatedByOrder
          : CustomSortCaret,
      style: {
        fontSize: '12px',
        color: 'phoenix-gray-1100'
      },
      formatter: (cell, row) => {
        return <>{row.added_time}</>;
      }
    },
    {
      dataField: 'status',
      text: 'STATUS',
      sort: true,
      sortCaret:
        columnName === 'status'
          ? CustomSortCaretUpdatedByOrder
          : CustomSortCaret,
      headerStyle: { width: '15%', fontSize: '13px' },
      style: {
        fontSize: '12px',
        color: 'phoenix-gray-1100'
      },
      formatter: (cell, row) => {
        if (row.status === 'Sold') {
          return (
            <Badge
              bg={'success'}
              variant="phoenix"
              iconPosition="end"
              className="fs-10"
              icon={<FeatherIcon icon={'check'} size={12} className="ms-1" />}
            >
              {row.status}
            </Badge>
          );
        } else {
          return (
            <Badge
              bg={'danger'}
              variant="phoenix"
              iconPosition="end"
              className="fs-10"
              icon={<FeatherIcon icon={'info'} size={12} className="ms-1" />}
            >
              {row.status}
            </Badge>
          );
        }
      }
    }
  ];

  const handleAllClick = () => {
    setCurrentPage(1);
    const start =
      startDate === null ? null : moment(startDate).format('YYYY-MM-DD');
    const end = endDate === null ? null : moment(endDate).format('YYYY-MM-DD');

    loadData(1, 10, 'id:desc', start, end);
  };

  const tabItems = [
    {
      label: 'All',
      value: 'all',
      count: total,
      onClick: handleAllClick
    }
  ];

  const table = useAdvanceTable({
    data: data
  });

  const handleTableChange = (
    type,
    { page, sizePerPage, sortField, sortOrder, data }
  ) => {
    switch (type) {
      case 'pagination':
        pagination(page, sizePerPage);
        break;
      case 'sort':
        sort(sortField, sortOrder, data);
        break;
      default:
        break;
    }
  };

  const pagination = (page, sizePerPage) => {
    const start =
      startDate === null ? null : moment(startDate).format('YYYY-MM-DD');
    const end = endDate === null ? null : moment(endDate).format('YYYY-MM-DD');
    setCurrentPage(page);
    setPageSize(sizePerPage);
    loadData(page, sizePerPage, sortString, start, end);
  };

  const sort = sortField => {
    setCurrentPage(1);
    if (sortColumn === 'desc') {
      setSortColumn('asc');
    } else if (sortColumn === 'asc') {
      setSortColumn('desc');
    }

    const start =
      startDate === null ? null : moment(startDate).format('YYYY-MM-DD');
    const end = endDate === null ? null : moment(endDate).format('YYYY-MM-DD');

    switch (sortField) {
      case 'name':
        setColumnName('name');
        setSortString(`item_stock.item.name:${sortColumn}`);
        loadData(1, 10, `item_stock.item.name:${sortColumn}`, start, end);
        break;
      case 'sku':
        setColumnName('sku');
        setSortString(`item_stock.item.sku:${sortColumn}`);
        loadData(1, 10, `item_stock.item.sku:${sortColumn}`, start, end);
        break;
      case 'description':
        setColumnName('description');
        setSortString(`item_stock.item.description:${sortColumn}`);
        loadData(
          1,
          10,
          `item_stock.item.description:${sortColumn}`,
          start,
          end
        );
        break;
      case 'category':
        setColumnName('category');
        setSortString(`item_stock.item.item_category.name:${sortColumn}`);
        loadData(
          1,
          10,
          `item_stock.item.item_category.name:${sortColumn}`,
          start,
          end
        );
        break;
      case 'added_user':
        setColumnName('added_user');
        setSortString(`user.first_name:${sortColumn}`);
        loadData(1, 10, `user.first_name:${sortColumn}`, start, end);
        break;
      case 'added_time':
        setColumnName('added_time');
        setSortString(`added_time:${sortColumn}`);
        loadData(1, 10, `added_time:${sortColumn}`, start, end);
        break;
      case 'status':
        setColumnName('status');
        setSortString(`item_stock.item.status.name:${sortColumn}`);
        loadData(
          1,
          10,
          `item_stock.item.status.name:${sortColumn}`,
          start,
          end
        );
        break;
      case 'location':
        setColumnName('location');
        setSortString(`item_stock.item.container.location.name:${sortColumn}`);
        loadData(
          1,
          10,
          `item_stock.item.container.location.name:${sortColumn}`,
          start,
          end
        );
        break;
      case 'serial_number':
        setColumnName('serial_number');
        setSortString(`item_stock.serial_number:${sortColumn}`);
        loadData(1, 10, `item_stock.serial_number:${sortColumn}`, start, end);
        break;
      default:
        break;
    }
  };

  const handleLoadOrders = () => {
    setCurrentPage(1);
    setClearButtonVisibility(true);
    loadData(
      1,
      10,
      'id:desc',
      startDate === null ? null : moment(startDate).format('YYYY-MM-DD'),
      endDate === null ? null : moment(endDate).format('YYYY-MM-DD')
    );

    loadDownLoadData(
      startDate === null ? null : moment(startDate).format('YYYY-MM-DD'),
      endDate === null ? null : moment(endDate).format('YYYY-MM-DD')
    );
  };

  const handleClearFilters = () => {
    setCurrentPage(1);
    setStartDate(null);
    setEndDate(null);
    setClearButtonVisibility(false);
    loadData(currentPage, pageSize, 'id:desc', null, null);
    loadDownLoadData(null, null);
  };

  return (
    <div>
      <div className="mb-9">
        <h2 className="mb-4">Orders</h2>
        <FilterTab tabItems={tabItems} className="mb-2" />

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <div className="d-flex flex-wrap gap-3">
              <div className="scrollbar overflow-hidden-y">
                <DatePicker
                  selected={startDate}
                  onChange={date => setStartDate(date)}
                  placeholder="Start Date"
                  className="form-control"
                  placeholderText="Select start date"
                  dateFormat="MM-dd-yyyy"
                />
              </div>

              <div className="scrollbar overflow-hidden-y">
                <DatePicker
                  selected={endDate}
                  onChange={date => setEndDate(date)}
                  className="form-control"
                  placeholderText="Select end date"
                  dateFormat="MM-dd-yyyy"
                />
              </div>

              <div className="scrollbar overflow-hidden-y">
                <Button
                  variant="primary"
                  type="button"
                  onClick={() => handleLoadOrders()}
                >
                  Load Orders
                </Button>
              </div>

              <div className="scrollbar overflow-hidden-y">
                <Button
                  variant="phoenix-secondary"
                  style={{
                    visibility:
                      clearButtonVisibility === true ? 'visible' : 'hidden'
                  }}
                  onClick={() => handleClearFilters()}
                >
                  Clear Filters
                </Button>
              </div>

              <div className="ms-xxl-auto">
                <div style={{ display: 'inline-block' }}>
                  <Button
                    variant="primary"
                    onClick={() => navigate(`/orders/add-order`)}
                  >
                    <FontAwesomeIcon icon={faPlus} className="me-2" />
                    Add Order
                  </Button>
                </div>
                <div style={{ display: 'inline-block', marginLeft: '5px' }}>
                  <DownloadButton data={downLoadData && downLoadData} />
                </div>
              </div>
            </div>
          </div>

          <div>
            {Object.entries(error).length > 0 &&
              error.map((e, index) => (
                <Alert variant="soft-danger" key={index}>
                  {e}
                </Alert>
              ))}
          </div>

          <div className="bg-white border-top border-bottom border-200 position-relative top-1">
            {loading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: '200px' }}
              >
                <Spinner animation="border" role="status"></Spinner>
              </div>
            ) : (
              <PaginationProvider
                pagination={paginationFactory({
                  custom: true,
                  page: currentPage,
                  sizePerPage: pageSize,
                  totalSize: total,
                  sizePerPageList: [
                    {
                      text: '10',
                      value: 10
                    }
                  ]
                })}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <div>
                    <BootstrapTable
                      remote
                      keyField="id"
                      data={data.length === 0 ? [] : data}
                      columns={columns}
                      bordered={false}
                      onTableChange={handleTableChange}
                      {...paginationTableProps}
                    />
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          marginTop: '-10px',
                          fontSize: '13px'
                        }}
                      >
                        <PaginationTotalStandalone {...paginationProps} />
                        <PaginationListStandalone {...paginationProps} />
                      </div>
                    </div>
                  </div>
                )}
              </PaginationProvider>
            )}
          </div>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default Orders;
