import { useState, useRef, useEffect } from 'react';
import Button from 'components/base/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert, Form, Row, Col, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import LoadingOverlay from 'components/loading-overlay/LoadingOverlay';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { patchUserPin, getUser } from 'api/api';

const breadcrumbItems = [
  {
    label: 'Users',
    url: '/users/users'
  },
  {
    label: 'Change Pin',
    active: true
  }
];

const UpdatePinUser = () => {
  const navigate = useNavigate();
  const passwordInputRef = useRef(null);
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pin, setPin] = useState('');
  const [confirmPin, setConfirmPin] = useState('');
  const [pinMatch, setPinMatch] = useState(true);
  const [showPin, setShowPin] = useState(false);
  const [showConfirmPin, setShowConfirmPin] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const { id } = useParams();

  useEffect(() => {
    if (passwordInputRef.current) {
      passwordInputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    loadData(id);
  }, [id]);

  const loadData = id => {
    setError([]);

    getUser(id)
      .then(response => {
        setFirstName(response.data.data.first_name);
        setLastName(response.data.data.last_name);
      })
      .catch(err => {
        try {
          setError(err.response.data.errors);
        } catch (ex) {
          setError(['Please try again later...']);
        }
      });
  };

  const handleSubmit = e => {
    e.preventDefault();
    setPinMatch(true);

    if (pin.length !== 4) {
      setError(['Pin should be a 4 digit number']);
      return;
    }

    if (pin !== confirmPin) {
      setPinMatch(false);
      return;
    }

    setError([]);
    setLoading(true);

    const formData = {
      login_pin: pin
    };

    patchUserPin(id, formData)
      .then(response => {
        if (response.status === 200) {
          navigate('/users/users');
        }
      })
      .catch(err => {
        try {
          if (err.response.status === 401 || err.response.status === 400) {
            navigate('/sign-in');
          }
          setError(err.response.data.errors);
        } catch (error) {
          setError(['Please try again later...']);
        }
      });

    setTimeout(function () {
      setLoading(false);
    }, 1000);
  };

  const handleChangePin = event => {
    const inputValue = event.target.value;
    const regex = /^[0-9]*$/;
    if (regex.test(inputValue)) {
      setPin(inputValue);
    }
  };

  const handleChangeConfirmPin = event => {
    const inputValue = event.target.value;
    const regex = /^[0-9]*$/;
    if (regex.test(inputValue)) {
      setConfirmPin(inputValue);
    }
  };

  return (
    <div>
      <PageBreadcrumb items={breadcrumbItems} />
      <div className="mb-9">
        <div className="d-flex flex-wrap gap-3 flex-between-end mb-5">
          <div>
            <h2 className="mb-2">Change Pin</h2>
            <h5 className="text-700 fw-semi-bold">{`Change ${firstName} ${lastName}'s  pin`}</h5>
          </div>
        </div>
        <div>
          {Object.entries(error).length > 0 &&
            error.map((e, index) => (
              <Alert variant="soft-danger" key={index}>
                {e}
              </Alert>
            ))}
        </div>

        {loading && <LoadingOverlay />}

        <div>
          <form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12} xl={4}>
                <label>Pin*</label>
                <InputGroup>
                  <Form.Control
                    className="mb-2"
                    type={showPin ? 'text' : 'password'}
                    value={pin}
                    placeholder="Enter a 4-digit number"
                    onChange={e => handleChangePin(e)}
                    maxLength={4}
                    required
                  />
                  <InputGroup.Text
                    onClick={() => setShowPin(!showPin)}
                    style={{ height: '38px' }}
                  >
                    <FontAwesomeIcon icon={showPin ? faEyeSlash : faEye} />
                  </InputGroup.Text>
                </InputGroup>
              </Col>
            </Row>

            <Row>
              <Col xs={12} xl={4}>
                <label>Confirm Pin*</label>
                <InputGroup>
                  <Form.Control
                    className="mb-2"
                    type={showConfirmPin ? 'text' : 'password'}
                    value={confirmPin}
                    onChange={e => handleChangeConfirmPin(e)}
                    maxLength={4}
                    required
                  />
                  <InputGroup.Text
                    onClick={() => setShowConfirmPin(!showConfirmPin)}
                    style={{ height: '38px' }}
                  >
                    <FontAwesomeIcon
                      icon={showConfirmPin ? faEyeSlash : faEye}
                    />
                  </InputGroup.Text>{' '}
                </InputGroup>
                {!pinMatch && (
                  <p className="mb-2" style={{ color: 'red' }}>
                    Pin do not match.
                  </p>
                )}
              </Col>
            </Row>

            <Row>
              <Col xs={12} xl={4}>
                <Button variant="primary" type="submit" className="mb-2">
                  Submit
                </Button>{' '}
                <Button
                  className="mb-2"
                  variant="phoenix-secondary"
                  type="button"
                  onClick={() => navigate('/users/users')}
                >
                  Discard
                </Button>
              </Col>
            </Row>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UpdatePinUser;
