import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';
import * as XLSX from 'xlsx';
import moment from 'moment';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import 'bootstrap/dist/css/bootstrap.min.css';

DownloadButton.propTypes = {
  data: PropTypes.any.isRequired,
  info: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  savePath: PropTypes.string.isRequired,
  allCount: PropTypes.string.isRequired,
  missingCount: PropTypes.string.isRequired,
  inStockCount: PropTypes.string.isRequired,
  soldCount: PropTypes.string.isRequired,
  foundCount: PropTypes.string.isRequired,
  missingInStockCount: PropTypes.string.isRequired
};

function DownloadButton({
  data,
  info,
  title,
  savePath,
  allCount,
  missingCount,
  inStockCount,
  soldCount,
  foundCount,
  missingInStockCount
}) {
  const handleDownlandXlsx = () => {
    downloadXlsx();
  };
  const handleDownlandCsv = () => {
    downloadCsv();
  };

  const handleDownlandPdf = () => {
    downloadPdf();
  };

  const downloadXlsx = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);

    const colWidths = [
      { wpx: 200 },
      { wpx: 200 },
      { wpx: 200 },
      { wpx: 200 },
      { wpx: 200 },
      { wpx: 200 },
      { wpx: 200 },
      { wpx: 200 }
    ];
    worksheet['!cols'] = colWidths;

    XLSX.utils.sheet_add_aoa(worksheet, [[]], { origin: -1 });

    XLSX.utils.sheet_add_aoa(worksheet, [[title]], {
      origin: -1
    });

    XLSX.utils.sheet_add_aoa(worksheet, [[info]], { origin: -1 });

    XLSX.utils.sheet_add_aoa(worksheet, [[`All Weapons: ${allCount}`]], {
      origin: -1
    });

    if (foundCount !== undefined)
      XLSX.utils.sheet_add_aoa(worksheet, [[`Found: ${foundCount}`]], {
        origin: -1
      });

    if (missingCount !== undefined)
      XLSX.utils.sheet_add_aoa(worksheet, [[`Missing: ${missingCount}`]], {
        origin: -1
      });

    if (inStockCount !== undefined)
      XLSX.utils.sheet_add_aoa(worksheet, [[`In Stock: ${inStockCount}`]], {
        origin: -1
      });

    if (soldCount !== undefined)
      XLSX.utils.sheet_add_aoa(worksheet, [[`Checked Out: ${soldCount}`]], {
        origin: -1
      });

    if (missingInStockCount !== undefined) {
      XLSX.utils.sheet_add_aoa(
        worksheet,
        [[`Missing in Stock: ${missingInStockCount}`]],
        {
          origin: -1
        }
      );
    }

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, savePath);

    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array'
    });

    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = `${savePath}-${moment(new Date()).format('MM-DD-YYYY')}`;
    a.click();

    // Cleanup
    URL.revokeObjectURL(url);
  };

  const downloadCsv = () => {
    const csvFilename = `${savePath}-${moment(new Date()).format(
      'MM-DD-YYYY'
    )}.csv`;

    let stringArray = data.map(obj => [
      obj.serial_number,
      obj.make,
      obj.model,
      obj.category,
      obj.calibre,
      obj.epc,
      obj.access_by,
      obj.time,
      obj.status
    ]);

    const headerArray = [
      'serial_number',
      'make',
      'model',
      'category',
      'calibre',
      'epc',
      'access_by',
      'time',
      'status'
    ];

    stringArray = [headerArray, ...stringArray];

    stringArray = [...stringArray, []];
    stringArray = [...stringArray, [[title]]];
    stringArray = [...stringArray, [info]];
    stringArray = [...stringArray, [`All Weapons: ${allCount}`]];

    if (foundCount !== undefined)
      stringArray = [...stringArray, [`Found: ${foundCount}`]];

    if (missingCount !== undefined)
      stringArray = [...stringArray, [`Missing: ${missingCount}`]];

    if (inStockCount !== undefined)
      stringArray = [...stringArray, [`In Stock: ${inStockCount}`]];

    if (inStockCount !== undefined)
      stringArray = [...stringArray, [`Checked Out: ${soldCount}`]];

    if (missingInStockCount !== undefined)
      stringArray = [
        ...stringArray,
        [`Missing in Stock: ${missingInStockCount}`]
      ];

    const csvData = stringArray.map(row => String(row)).join('\n');

    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', csvFilename);
    document.body.appendChild(link);
    link.click();

    // Cleanup
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const downloadPdf = () => {
    const unit = 'pt';
    const size = 'A4'; // Use A1, A2, A3 or A4
    const orientation = 'portrait'; // portrait or landscape

    const pdf = new jsPDF(orientation, unit, size);
    pdf.text(title, 40, 50);

    pdf.setFontSize(10);
    pdf.text(info, 40, 70);
    pdf.text(`All Weapons: ${allCount}`, 40, 90);

    if (missingCount !== undefined)
      pdf.text(`Missing: ${missingCount}`, 40, 110);

    if (inStockCount !== undefined)
      pdf.text(`In Stock: ${inStockCount}`, 40, 130);

    if (soldCount !== undefined) pdf.text(`Checked Out: ${soldCount}`, 40, 150);

    if (foundCount !== undefined) pdf.text(`Found: ${foundCount}`, 40, 130);

    if (missingInStockCount !== undefined)
      pdf.text(`Missing in Stock: ${missingInStockCount}`, 40, 150);

    const headers = [
      [
        'Serial Number',
        'Make',
        'Model',
        'Category',
        'Calibre',
        'Epc',
        'access_by',
        'time',
        'Status'
      ]
    ];
    const rows = data.map(obj => [
      obj.serial_number,
      obj.make,
      obj.model,
      obj.category,
      obj.calibre,
      obj.epc,
      obj.access_by,
      obj.time,
      obj.status
    ]);

    const headerStyles = {
      fillColor: '#ffffff',
      textColor: '#000000',
      fontStyle: 'bold'
    };

    const bodyStyles = {
      fillColor: '#ffffff'
    };

    pdf.autoTable({
      head: headers,
      body: rows,
      headStyles: headerStyles,
      alternateRowStyles: bodyStyles,
      startY: 170,
      willDrawCell: data => {
        // Draw horizontal lines after each row
        pdf.setDrawColor(0, 0, 0);
        pdf.setLineWidth(3);
        pdf.line(
          data.cell.x,
          data.cell.y,
          data.cell.x + data.cell.width,
          data.cell.y
        );
      }
    });

    // Save the PDF as a file
    pdf.save(`${savePath}-${moment(new Date()).format('MM-DD-YYYY')}.pdf`);
  };

  return (
    <>
      <Dropdown>
        <Dropdown.Toggle
          variant="phoenix-secondary"
          size="sm"
          className="bg-white hover-bg-100 dropdown-caret-none"
        >
          <FontAwesomeIcon icon={faDownload} className="10"></FontAwesomeIcon>
        </Dropdown.Toggle>

        <Dropdown.Menu align="start">
          <Dropdown.Item href="#" onClick={e => handleDownlandXlsx(e)}>
            XLSX
          </Dropdown.Item>
          <Dropdown.Item href="#" onClick={e => handleDownlandCsv(e)}>
            CSV
          </Dropdown.Item>
          <Dropdown.Item href="#" onClick={e => handleDownlandPdf(e)}>
            PDF
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}

export default DownloadButton;
