import { useState, useRef, useEffect } from 'react';
import Button from 'components/base/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert, Form, Row, Col } from 'react-bootstrap';
import LoadingOverlay from 'components/loading-overlay/LoadingOverlay';
import Select from 'react-select';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import makeAnimated from 'react-select/animated';
import {
  getItemUnits,
  deleteItem,
  getSubCategories,
  getLocations,
  getContainerByLocationId,
  getItem
} from 'api/api';

const breadcrumbItems = [
  {
    label: 'Weapons',
    url: '/weapons/weapons'
  },
  {
    label: 'Delete',
    active: true
  }
];

const DeleteWeapon = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const nameInputRef = useRef(null);
  const animatedComponents = makeAnimated();
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState(false);
  const [subCategoryName, setSubCategoryName] = useState([]);
  const [containerName, setContainerName] = useState([]);
  const [unitNames, setUnitNames] = useState([]);
  const [locationName, setLocationName] = useState([]);
  const [name, setName] = useState('');
  const [sku, setSku] = useState('');
  const [category_id, setCategory_id] = useState(null);
  const [location_id, setLocation_id] = useState(null);
  const [container_id, setContainer_id] = useState(null);
  const [item_unit_id, setItem_unit_id] = useState(null);
  const [description, setDescription] = useState('');

  useEffect(() => {
    if (nameInputRef.current) {
      nameInputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    loadData(id);
  }, []);

  const loadData = async id => {
    setError([]);
    setLoading(true);

    await loadSubCategories();
    await loadLocations();
    await loadItemUnits();

    getItem(id)
      .then(async response => {
        const data = response.data.data;

        await loadContainers(data.container.location.id);

        setName(data.name);
        setSku(data.sku);

        setLocation_id({
          value: data.container.location.id,
          label: data.container.location.name
        });

        setContainer_id({
          value: data.container.id,
          label: data.container.name
        });

        setDescription(data.description === null ? '' : data.description);
        setItem_unit_id({
          value: data.item_unit.id,
          label: data.item_unit.unit
        });

        setCategory_id({ value: data.category.id, label: data.category.name });
      })
      .catch(err => {
        try {
          setError(err.response.data.errors);
        } catch (ex) {
          setError(['Please try again later...']);
        }
      });

    setTimeout(function () {
      setLoading(false);
    }, 1000);
  };

  const loadSubCategories = async () => {
    setError([]);

    getSubCategories(1, 0, 'name:asc')
      .then(response => {
        const data = response.data.data.data;
        setSubCategoryName(data);
      })
      .catch(err => {
        try {
          setError(err.response.data.errors);
        } catch (ex) {
          setError(['Please try again later...']);
        }
      });
  };

  const loadLocations = async () => {
    setError([]);

    getLocations()
      .then(response => {
        const data = response.data.data.data;
        setLocationName(data);
      })
      .catch(err => {
        try {
          setError(err.response.data.errors);
        } catch (ex) {
          setError(['Please try again later...']);
        }
      });
  };

  const loadContainers = async id => {
    setError([]);
    setLoading(true);

    await getContainerByLocationId(id)
      .then(response => {
        const data = response.data.data;
        setContainerName(data);
      })
      .catch(err => {
        try {
          setError(err.response.data.errors);
        } catch (ex) {
          setError(['Please try again later...']);
        }
      });

    setTimeout(function () {
      setLoading(false);
    }, 1000);
  };

  const loadItemUnits = async () => {
    setError([]);
    setLoading(true);

    await getItemUnits()
      .then(response => {
        const data = response.data.data;
        setUnitNames(data);
      })
      .catch(err => {
        try {
          setError(err.response.data.errors);
        } catch (ex) {
          setError(['Please try again later...']);
        }
      });

    setTimeout(function () {
      setLoading(false);
    }, 1000);
  };

  const handleSubmit = e => {
    e.preventDefault();

    setError([]);
    setLoading(true);

    deleteItem(id)
      .then(response => {
        if (response.status === 200) navigate('/weapons/weapons');
      })
      .catch(err => {
        try {
          setError(err.response.data.errors);
        } catch (error) {
          setError(['Please try again later...']);
        }
      });

    setTimeout(function () {
      setLoading(false);
    }, 1000);
  };

  const handleChangeLocation = e => {
    loadContainers(e.value);
    setLocation_id(e.value);
  };

  const customStyles = {
    singleValue: provided => ({
      ...provided,
      fontSize: '12px'
    })
  };

  return (
    <div>
      <PageBreadcrumb items={breadcrumbItems} />
      <div className="mb-9">
        <div className="d-flex flex-wrap gap-3 flex-between-end mb-5">
          <div>
            <h2 className="mb-2">Delete Weapon</h2>
            <h5 className="text-700 fw-semi-bold">Delete a weapon</h5>
          </div>
        </div>
        <div>
          {Object.entries(error).length > 0 &&
            error.map((e, index) => (
              <Alert variant="soft-danger" key={index}>
                {e}
              </Alert>
            ))}
        </div>

        {loading && <LoadingOverlay />}

        <div>
          <form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12} xl={4}>
                <label>Make*</label>
                <Form.Control
                  className="mb-2"
                  type="text"
                  value={name}
                  onChange={e => setName(e.target.value)}
                  ref={nameInputRef}
                  disabled={true}
                />
              </Col>

              <Col xs={12} xl={4}>
                <label>Model*</label>
                <Form.Control
                  className="mb-2"
                  type="text"
                  value={description}
                  onChange={e => setDescription(e.target.value)}
                  disabled={true}
                />
              </Col>
            </Row>

            <Row>
              <Col xs={12} xl={4}>
                <label>Category*</label>
                <Select
                  classNamePrefix="react-select"
                  className="mb-2"
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  options={
                    subCategoryName &&
                    subCategoryName.map(c => ({
                      value: c.id,
                      label: c.name
                    }))
                  }
                  onChange={e => setCategory_id(e)}
                  value={category_id}
                  isDisabled={true}
                  name="sub_category"
                  menuPlacement="auto"
                  styles={customStyles}
                />
              </Col>

              <Col xs={12} xl={4}>
                <label>Calibre*</label>
                <Form.Control
                  className="mb-2"
                  type="text"
                  value={sku}
                  onChange={e => setSku(e.target.value)}
                  disabled={true}
                />
              </Col>
            </Row>

            <Row>
              <Col xs={12} xl={4}>
                <label>Location*</label>
                <Select
                  classNamePrefix="react-select"
                  className="mb-2"
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  options={
                    locationName &&
                    locationName.map(c => ({
                      value: c.id,
                      label: c.name
                    }))
                  }
                  onChange={e => handleChangeLocation(e)}
                  value={location_id}
                  isDisabled={true}
                  name="location"
                  menuPlacement="auto"
                  styles={customStyles}
                />
              </Col>

              <Col xs={12} xl={4}>
                <label>Container*</label>
                <Select
                  classNamePrefix="react-select"
                  className="mb-2"
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  options={
                    containerName &&
                    containerName.map(c => ({
                      value: c.id,
                      label: c.name
                    }))
                  }
                  value={container_id}
                  onChange={e => setContainer_id(e)}
                  isDisabled={true}
                  name="container"
                  menuPlacement="auto"
                  styles={customStyles}
                />
              </Col>
            </Row>

            <Row>
              <Col xs={12} xl={4}>
                <label>Unit*</label>
                <Select
                  classNamePrefix="react-select"
                  className="mb-2"
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  options={
                    unitNames &&
                    unitNames.map(c => ({
                      value: c.id,
                      label: c.unit
                    }))
                  }
                  onChange={e => setItem_unit_id(e)}
                  value={item_unit_id}
                  isDisabled={true}
                  name="unit"
                  menuPlacement="auto"
                  styles={customStyles}
                />
              </Col>
            </Row>

            <Row>
              <Col xs={12} xl={4}>
                <Button className="mb-2" variant="danger" type="submit">
                  Delete
                </Button>{' '}
                <Button
                  className="mb-2"
                  variant="phoenix-secondary"
                  type="button"
                  onClick={() => navigate('/weapons/weapons')}
                >
                  Discard
                </Button>
              </Col>
            </Row>
          </form>
        </div>
      </div>
    </div>
  );
};

export default DeleteWeapon;
