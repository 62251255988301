import { useState } from 'react';
import { Col, Row, Alert } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingOverlay from 'components/loading-overlay/LoadingOverlay';
import Button from 'components/base/Button';
import { DropzoneAreaBase } from 'material-ui-dropzone';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { patchImage } from 'api/api';

const breadcrumbItems = [
  {
    label: 'Weapons',
    url: '/weapons/weapons'
  },
  {
    label: 'Image',
    active: true
  }
];

function UploadWeaponImage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);

  const handleSubmit = () => {
    setError([]);

    if (files.length === 0) return;

    setLoading(true);
    const file = files[0];

    const formData = new FormData();
    formData.append('image', file.file);

    patchImage(id, formData)
      .then(response => {
        if (response.status === 200) navigate('/weapons/weapons');
      })
      .catch(err => {
        try {
          setError(err.response.data.errors);
        } catch (ex) {
          setError(['Please try again later...']);
        }
      });

    setTimeout(function () {
      setLoading(false);
    }, 1000);
  };

  const handleAdd = newFiles => {
    newFiles = newFiles.filter(file => !files.find(f => f.data === file.data));
    setFiles([...files, ...newFiles]);
  };

  const handleDelete = deleted => {
    setFiles(files.filter(f => f !== deleted));
  };

  return (
    <div>
      <PageBreadcrumb items={breadcrumbItems} />
      <div className="mb-9">
        <div className="d-flex flex-wrap gap-3 flex-between-end mb-5">
          <div>
            <h2 className="mb-2">{`Add a Weapon Image`}</h2>
            <h5 className="text-700 fw-semi-bold">Add a new weapon image</h5>
          </div>
        </div>
        <Row className="g-5">
          <Col xs={12} xl={8}>
            <div>
              <div>
                {Object.entries(error).length > 0 &&
                  error.map((e, index) => (
                    <Alert variant="soft-danger" key={index}>
                      {e}
                    </Alert>
                  ))}
              </div>
              {loading && <LoadingOverlay />}

              <DropzoneAreaBase
                fileObjects={files}
                onAdd={handleAdd}
                onDelete={handleDelete}
                acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                maxFileSize={5000000}
                filesLimit={1}
                dropzoneText={'Drag and drop an image here or click'}
              />

              {files && (
                <div
                  className="d-flex flex-wrap gap-2"
                  style={{ marginTop: '10px' }}
                >
                  <Button
                    variant="primary"
                    type="button"
                    onClick={e => handleSubmit(e)}
                  >
                    Submit
                  </Button>
                  <Button
                    variant="phoenix-secondary"
                    type="button"
                    onClick={() => navigate('/weapons/weapons')}
                  >
                    Discard
                  </Button>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default UploadWeaponImage;
