import { useEffect, useState } from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import SearchBox from 'components/common/SearchBox';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { useNavigate, Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationTotalStandalone,
  PaginationProvider,
  PaginationListStandalone
} from 'react-bootstrap-table2-paginator';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Dropdown, Alert, Spinner } from 'react-bootstrap';
import Badge from 'components/base/Badge';
import FilterTab from 'components/common/FilterTab';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import FeatherIcon from 'feather-icons-react';
import useAdvanceTable from 'hooks/useAdvanceTable';
import { CustomSortCaret } from 'helpers/ui-utils';
import {
  getItems,
  getLocations,
  getContainerByLocationId,
  getSubCategories
} from 'api/api';

const Weapons = () => {
  const animatedComponents = makeAnimated();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [error, setError] = useState([]);
  const [total, setTotal] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [containerName, setContainerName] = useState([]);
  const [locationName, setLocationName] = useState([]);
  const [sortColumn, setSortColumn] = useState('asc');
  const [selectedContainer, setSelectedContainer] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [columnName, setColumnName] = useState('id');
  const [stats, setStats] = useState([]);
  const [statusId, setStatusId] = useState('');
  const [clearButtonVisibility, setClearButtonVisibility] = useState(false);
  const [sortString, setSortString] = useState('id:desc');
  const [categoryName, setCategoryName] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    loadData(currentPage, pageSize, '', 'id:desc', null, null, null, null);
    loadCategories('all');
    loadLocations();
  }, []);

  const loadData = (
    pageNr,
    pageSize,
    statusId,
    sortBy,
    locationId,
    containerId,
    q,
    categoryId
  ) => {
    setError([]);
    setLoading(true);
    setData([]);

    getItems(
      pageNr,
      pageSize,
      statusId,
      sortBy,
      locationId,
      containerId,
      q,
      categoryId
    )
      .then(response => {
        setTotal(response.data.data.total);
        setStats(response.data.data.stats);
        if (response.data.data.data.length > 0) {
          const flattenedData = response.data.data.data.map(item => ({
            id: item.id,
            name: item.name,
            sku: item.sku,
            parent_category: item.category.parent_category.name,
            description: item.description,
            category: item.category.name,
            container: item.container.name,
            location: item.container.location.name,
            quantity: item.quantity,
            status: item.status.name,
            image: item.image
          }));

          setData(flattenedData);
        }
      })
      .catch(err => {
        console.log(err.response);

        try {
          if (err.response.status === 401 || err.response.status === 400) {
            navigate('/sign-in');
          }

          setError(err.response.data.errors);
        } catch (ex) {
          console.log(ex);
          setError(['Please try again later...']);
        }
      });

    setTimeout(function () {
      setLoading(false);
    }, 1000);
  };

  const loadCategories = () => {
    setError([]);
    setLoading(true);

    getSubCategories(1, 0, `name:asc`)
      .then(response => {
        const data = response.data.data.data;
        setCategoryName(data);
      })
      .catch(err => {
        try {
          setError(err.response.data.errors);
        } catch (ex) {
          setError(['Please try again later...']);
        }
      });

    setTimeout(function () {
      setLoading(false);
    }, 1000);
  };

  const loadLocations = () => {
    setError([]);
    setLoading(true);

    getLocations()
      .then(response => {
        const data = response.data.data.data;
        setLocationName(data);
      })
      .catch(err => {
        try {
          setError(err.response.data.errors);
        } catch (ex) {
          setError(['Please try again later...']);
        }
      });

    setTimeout(function () {
      setLoading(false);
    }, 1000);
  };

  const loadContainers = id => {
    setError([]);
    setLoading(true);

    getContainerByLocationId(id)
      .then(response => {
        const data = response.data.data;
        setContainerName(data);
      })
      .catch(err => {
        try {
          setError(err.response.data.errors);
        } catch (ex) {
          console.log(ex);
          setError(['Please try again later...']);
        }
      });

    setTimeout(function () {
      setLoading(false);
    }, 1000);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const CustomSortCaretUpdatedByOrder = (order, dataField) => {
    if (columnName === 'id')
      return (
        <span className="custom-sort-cart">
          <div style={{ display: 'inline-block' }}>
            ▲
            <br />▼
          </div>
        </span>
      );
    else if (sortColumn === 'asc')
      return (
        <span className="custom-sort-cart">
          <div style={{ display: 'inline-block' }}>
            <div style={{ display: 'inline-block' }}> ▲ </div>
            <br />
            <div style={{ display: 'inline-block', visibility: 'collapse' }}>
              ▼
            </div>
          </div>
        </span>
      );
    else if (sortColumn === 'desc')
      return <span className="custom-sort-cart">▼</span>;
    return null;
  };

  const columns = [
    {
      dataField: 'image',
      formatter: (cellContent, row) => (
        <Link
          to={`/weapons/weapon-details/${row.id}`}
          className="rounded-2 border d-inline-block"
        >
          <img src={row.image} alt="" width={53} />
        </Link>
      ),
      headerStyle: () => ({ width: '80px' })
    },
    {
      dataField: 'name',
      text: 'MAKE',
      headerStyle: { width: '15%', fontSize: '13px' },
      sort: true,
      sortCaret:
        columnName === 'name' ? CustomSortCaretUpdatedByOrder : CustomSortCaret,
      style: {
        fontSize: '12px',
        fontWeight: 'bold',
        color: 'phoenix-gray-1100'
      },
      formatter: (cellContent, row) => (
        <Link
          to={`/weapons/weapon-details/${row.id}`}
          className="fw-semi-bold line-clamp-3"
        >
          {row.name}
        </Link>
      )
    },
    {
      dataField: 'description',
      text: 'MODEL',
      headerStyle: { width: '15%', fontSize: '13px' },
      sort: true,
      sortCaret:
        columnName === 'description'
          ? CustomSortCaretUpdatedByOrder
          : CustomSortCaret,
      style: {
        fontSize: '12px',
        color: 'phoenix-gray-1100'
      },
      formatter: (cell, row) => {
        return <> {row.description}</>;
      }
    },

    {
      dataField: 'category',
      text: 'CATEGORY',
      headerStyle: { width: '15%', fontSize: '13px' },
      sort: true,
      sortCaret:
        columnName === 'category'
          ? CustomSortCaretUpdatedByOrder
          : CustomSortCaret,
      style: {
        fontSize: '12px',
        color: 'phoenix-gray-1100'
      },
      formatter: (cell, row) => {
        return <> {row.category}</>;
      }
    },

    {
      dataField: 'sku',
      text: 'CALIBRE',
      headerStyle: { width: '10%', fontSize: '13px' },
      sort: true,
      sortCaret:
        columnName === 'sku' ? CustomSortCaretUpdatedByOrder : CustomSortCaret,
      style: {
        fontSize: '12px',
        color: 'phoenix-gray-1100'
      },
      formatter: (cell, row) => {
        return <>{row.sku}</>;
      }
    },

    {
      dataField: 'location',
      text: 'LOCATION',
      headerStyle: { width: '10%', fontSize: '13px' },
      sort: true,
      sortCaret:
        columnName === 'location'
          ? CustomSortCaretUpdatedByOrder
          : CustomSortCaret,
      style: {
        fontSize: '12px',
        color: 'phoenix-gray-1100'
      },
      formatter: (cell, row) => {
        return <> {row.location}</>;
      }
    },

    {
      dataField: 'container',
      text: 'CONTAINER',
      headerStyle: { width: '15%', fontSize: '13px' },
      sort: true,
      sortCaret:
        columnName === 'container'
          ? CustomSortCaretUpdatedByOrder
          : CustomSortCaret,
      style: {
        fontSize: '12px',
        color: 'phoenix-gray-1100'
      },
      formatter: (cell, row) => {
        return <> {row.container}</>;
      }
    },

    {
      dataField: 'quantity',
      text: 'QTY',
      headerStyle: { width: '10%', fontSize: '13px' },
      sort: true,
      sortCaret:
        columnName === 'quantity'
          ? CustomSortCaretUpdatedByOrder
          : CustomSortCaret,
      style: {
        fontSize: '12px',
        color: 'phoenix-gray-1100'
      }
    },

    {
      dataField: 'status',
      text: 'STATUS',
      sort: true,
      sortCaret:
        columnName === 'status'
          ? CustomSortCaretUpdatedByOrder
          : CustomSortCaret,
      headerStyle: { width: '8%', fontSize: '13px' },
      style: {
        fontSize: '12px',
        color: 'phoenix-gray-1100'
      },
      formatter: (cell, row) => {
        if (row.status === 'In Stock') {
          return (
            <Badge
              bg={'success'}
              variant="phoenix"
              iconPosition="end"
              className="fs-10"
              icon={<FeatherIcon icon={'check'} size={12} className="ms-1" />}
            >
              {row.status}
            </Badge>
          );
        } else {
          return (
            <Badge
              bg={'danger'}
              variant="phoenix"
              iconPosition="end"
              className="fs-10"
              icon={<FeatherIcon icon={'info'} size={12} className="ms-1" />}
            >
              {row.status}
            </Badge>
          );
        }
      }
    },

    {
      dataField: 'actions',
      formatter: (cell, row) => (
        <div className="table-action-button">
          <RevealDropdownTrigger>
            <RevealDropdown onSelect={eventKey => handleSelect(eventKey)}>
              <Dropdown.Item eventKey={`edit:${row.id}`}>Edit</Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item eventKey={`upload-image:${row.id}`}>
                Upload Image
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item
                className="text-success"
                eventKey={`stock:${row.id}`}
              >
                Stocks
              </Dropdown.Item>
              <Dropdown.Item eventKey={`stock-single:${row.id}`}>
                Add Stock
              </Dropdown.Item>
              <Dropdown.Item eventKey={`stock-bulk:${row.id}`}>
                Add Stock - Bulk
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item
                key={`${row.id}`}
                className="text-danger"
                eventKey={`delete:${row.id}`}
              >
                Delete
              </Dropdown.Item>
            </RevealDropdown>
          </RevealDropdownTrigger>
        </div>
      )
    }
  ];

  const handleSelect = eventKey => {
    // Handle the selection here
    const parts = eventKey.split(':');
    if (parts[0] === 'edit') {
      navigate(`/weapons/edit-weapon/${parts[1]}`);
    } else if (parts[0] === 'delete') {
      navigate(`/weapons/delete-weapon/${parts[1]}`);
    } else if (parts[0] === 'upload-image') {
      navigate(`/weapons/image-weapon/${parts[1]}`);
    } else if (parts[0] === 'stock') {
      navigate(`/weapons/stock-weapon/${parts[1]}`);
    } else if (parts[0] === 'stock-bulk') {
      navigate(`/weapons/stock-weapon/add-stock-bulk/${parts[1]}`, {
        state: { customState: 'stockItem' }
      });
    } else if (parts[0] === 'stock-single') {
      navigate(`/weapons/stock-weapon/add-stock/${parts[1]}`, {
        state: { customState: 'stockItem' }
      });
    }
  };

  const handleAllClick = id => {
    setCurrentPage(1);
    setStatusId(id);
    if (id === 'all') {
      loadData(
        1,
        10,
        '',
        'id:desc',
        selectedLocation === null ? null : selectedLocation.value,
        selectedContainer === null ? null : selectedContainer.value,
        searchText,
        selectedCategory === null ? null : selectedCategory.value
      );
    } else {
      loadData(
        1,
        10,
        id,
        'id:desc',
        selectedLocation === null ? null : selectedLocation.value,
        selectedContainer === null ? null : selectedContainer.value,
        searchText,
        selectedCategory === null ? null : selectedCategory.value
      );
    }
  };

  // const tabItems = [
  //   {
  //     label: 'All',
  //     value: 'all',
  //     count: allCount,
  //     onClick: handleAllClick
  //   }
  // ];

  const tabItems =
    stats &&
    stats
      .map(stat => ({
        label: stat.status ? stat.status.name : 'All',
        value: stat.status ? stat.status.id : 'all',
        count: stat.total,
        onClick: () => handleAllClick(stat.status ? stat.status.id : 'all')
      }))
      .sort((a, b) =>
        a.label === 'All' ? -1 : a.label.localeCompare(b.label)
      );

  const table = useAdvanceTable({
    data: data
  });

  const handleSearchInputChange = e => {
    setSearchText(e.target.value);
    setClearButtonVisibility(true);
    setCurrentPage(1);

    if (e.target.value.length >= 2) {
      loadData(
        1,
        10,
        statusId,
        'id:desc',
        selectedLocation === null ? null : selectedLocation.value,
        selectedContainer === null ? null : selectedContainer.value,
        e.target.value,
        selectedCategory === null ? null : selectedCategory.value
      );
    }
    if (e.target.value.length === 0) {
      loadData(
        1,
        10,
        statusId,
        'id:desc',
        selectedLocation === null ? null : selectedLocation.value,
        selectedContainer === null ? null : selectedContainer.value,
        null,
        selectedCategory === null ? null : selectedCategory.value
      );
    }
  };

  const handleTableChange = (
    type,
    { page, sizePerPage, sortField, sortOrder, data }
  ) => {
    switch (type) {
      case 'pagination':
        pagination(page, sizePerPage);
        break;
      case 'sort':
        sort(sortField, sortOrder, data);
        break;
      default:
        break;
    }
  };

  const pagination = (page, sizePerPage) => {
    setCurrentPage(page);
    setPageSize(sizePerPage);
    loadData(
      page,
      sizePerPage,
      statusId,
      sortString,
      selectedLocation === null ? null : selectedLocation.value,
      selectedContainer === null ? null : selectedContainer.value,
      searchText,
      selectedCategory === null ? null : selectedCategory.value
    );
  };

  const sort = sortField => {
    setCurrentPage(1);
    if (sortColumn === 'desc') {
      setSortColumn('asc');
    } else if (sortColumn === 'asc') {
      setSortColumn('desc');
    }
    switch (sortField) {
      case 'name':
        setColumnName('name');
        setSortString(`name:${sortColumn}`);
        loadData(
          1,
          10,
          statusId,
          `name:${sortColumn}`,
          selectedLocation === null ? null : selectedLocation.value,
          selectedContainer === null ? null : selectedContainer.value,
          searchText,
          selectedCategory === null ? null : selectedCategory.value
        );
        break;
      case 'sku':
        setColumnName('sku');
        setSortString(`sku:${sortColumn}`);
        loadData(
          1,
          10,
          statusId,
          `sku:${sortColumn}`,
          selectedLocation === null ? null : selectedLocation.value,
          selectedContainer === null ? null : selectedContainer.value,
          searchText,
          selectedCategory === null ? null : selectedCategory.value
        );
        break;
      case 'description':
        setColumnName('description');
        setSortString(`description:${sortColumn}`);
        loadData(
          1,
          10,
          statusId,
          `description:${sortColumn}`,
          selectedLocation === null ? null : selectedLocation.value,
          selectedContainer === null ? null : selectedContainer.value,
          searchText,
          selectedCategory === null ? null : selectedCategory.value
        );
        break;
      case 'parent_category':
        setColumnName('parent_category');
        setSortString(`item_category.item_category.name:${sortColumn}`);
        loadData(
          1,
          10,
          statusId,
          `item_category.item_category.name:${sortColumn}`,
          selectedLocation === null ? null : selectedLocation.value,
          selectedContainer === null ? null : selectedContainer.value,
          searchText,
          selectedCategory === null ? null : selectedCategory.value
        );
        break;
      case 'category':
        setColumnName('category');
        setSortString(`item_category.name:${sortColumn}`);
        loadData(
          1,
          10,
          statusId,
          `item_category.name:${sortColumn}`,
          selectedLocation === null ? null : selectedLocation.value,
          selectedContainer === null ? null : selectedContainer.value,
          searchText,
          selectedCategory === null ? null : selectedCategory.value
        );
        break;
      case 'container':
        setColumnName('container');
        setSortString(`container.name:${sortColumn}`);
        loadData(
          1,
          10,
          statusId,
          `container.name:${sortColumn}`,
          selectedLocation === null ? null : selectedLocation.value,
          selectedContainer === null ? null : selectedContainer.value,
          searchText,
          selectedCategory === null ? null : selectedCategory.value
        );
        break;
      case 'location':
        setColumnName('location');
        setSortString(`container.location.name:${sortColumn}`);
        loadData(
          1,
          10,
          statusId,
          `container.location.name:${sortColumn}`,
          selectedLocation === null ? null : selectedLocation.value,
          selectedContainer === null ? null : selectedContainer.value,
          searchText,
          selectedCategory === null ? null : selectedCategory.value
        );
        break;
      case 'quantity':
        setColumnName('quantity');
        setSortString(`quantity:${sortColumn}`);
        loadData(
          1,
          10,
          statusId,
          `quantity:${sortColumn}`,
          selectedLocation === null ? null : selectedLocation.value,
          selectedContainer === null ? null : selectedContainer.value,
          searchText,
          selectedCategory === null ? null : selectedCategory.value
        );
        break;
      case 'status':
        setColumnName('status');
        setSortString(`status.name:${sortColumn}`);
        loadData(
          1,
          10,
          statusId,
          `status.name:${sortColumn}`,
          selectedLocation === null ? null : selectedLocation.value,
          selectedContainer === null ? null : selectedContainer.value,
          searchText,
          selectedCategory === null ? null : selectedCategory.value
        );
        break;
      default:
        break;
    }
  };

  const customStyles = {
    control: provided => ({
      ...provided,
      fontSize: '12px'
    }),
    placeholder: provided => ({
      ...provided,
      fontSize: '12px'
    })
  };

  const handleChangeLocation = e => {
    setCurrentPage(1);
    setSelectedContainer(null);
    setClearButtonVisibility(true);
    setSelectedLocation(e);
    loadContainers(e.value);
    loadData(
      1,
      10,
      statusId,
      'id:desc',
      e.value,
      null,
      searchText,
      selectedCategory === null ? null : selectedCategory.value
    );
  };

  const handleChangeContainers = e => {
    setCurrentPage(1);
    setSelectedContainer(e);
    setClearButtonVisibility(true);
    loadData(
      1,
      10,
      statusId,
      'id:desc',
      selectedLocation.value,
      e.value,
      searchText,
      selectedCategory === null ? null : selectedCategory.value
    );
  };

  const handleChangeCategory = e => {
    setCurrentPage(1);
    setClearButtonVisibility(true);
    setSelectedCategory(e);
    loadData(
      1,
      10,
      statusId,
      'id:desc',
      selectedLocation === null ? null : selectedLocation.value,
      selectedContainer == null ? null : selectedContainer.value,
      searchText,
      e.value
    );
  };

  const handleClearFilters = () => {
    setCurrentPage(1);
    setSelectedContainer(null);
    setSelectedLocation(null);
    setContainerName([]);
    setSearchText('');
    setSelectedCategory(null);
    loadData(currentPage, pageSize, '', 'id:desc', null, null, null, null);
    setClearButtonVisibility(false);
  };

  return (
    <div>
      <div className="mb-9">
        <h2 className="mb-4">Weapons</h2>
        <FilterTab tabItems={tabItems} className="mb-2" />

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <div className="d-flex flex-wrap gap-3">
              <SearchBox
                placeholder="Search weapons"
                onChange={handleSearchInputChange}
                value={searchText}
              />
              <div style={{ width: '180px' }}>
                <Select
                  classNamePrefix="react-select"
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  options={
                    categoryName &&
                    categoryName.map(c => ({
                      value: c.id,
                      label: c.name
                    }))
                  }
                  placeholder={'Select category'}
                  value={selectedCategory}
                  styles={customStyles}
                  onChange={e => handleChangeCategory(e)}
                  required
                  name="location"
                  menuPlacement="auto"
                />
              </div>

              <div style={{ width: '180px' }}>
                <Select
                  classNamePrefix="react-select"
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  options={
                    locationName &&
                    locationName.map(c => ({
                      value: c.id,
                      label: c.name
                    }))
                  }
                  placeholder={'Select location'}
                  styles={customStyles}
                  value={selectedLocation}
                  onChange={e => handleChangeLocation(e)}
                  required
                  name="location"
                  menuPlacement="auto"
                />
              </div>
              <div style={{ width: '180px' }}>
                <Select
                  classNamePrefix="react-select"
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  options={
                    containerName &&
                    containerName.map(c => ({
                      value: c.id,
                      label: c.name
                    }))
                  }
                  value={selectedContainer}
                  onChange={e => handleChangeContainers(e)}
                  placeholder={'Select container'}
                  styles={customStyles}
                  required
                  name="parent_category"
                  menuPlacement="auto"
                />
              </div>
              <div style={{ width: '130px' }}>
                <Button
                  variant="phoenix-secondary"
                  style={{
                    visibility:
                      clearButtonVisibility === true ? 'visible' : 'hidden'
                  }}
                  onClick={() => handleClearFilters()}
                >
                  Clear Filters
                </Button>
              </div>

              <div className="ms-xxl-auto">
                <Button
                  variant="primary"
                  onClick={() => navigate(`/weapons/add-weapon/`)}
                >
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Add Weapon
                </Button>
              </div>
            </div>
          </div>

          <div>
            {Object.entries(error).length > 0 &&
              error.map((e, index) => (
                <Alert variant="soft-danger" key={index}>
                  {e}
                </Alert>
              ))}
          </div>

          <div className="bg-white border-top border-bottom border-200 position-relative top-1">
            {loading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: '200px' }}
              >
                <Spinner animation="border" role="status"></Spinner>
              </div>
            ) : (
              <PaginationProvider
                pagination={paginationFactory({
                  custom: true,
                  page: currentPage,
                  sizePerPage: pageSize,
                  totalSize: total,
                  sizePerPageList: [
                    {
                      text: '10',
                      value: 10
                    }
                  ]
                })}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <div>
                    <BootstrapTable
                      remote
                      keyField="id"
                      data={data.length === 0 ? [] : data}
                      columns={columns}
                      bordered={false}
                      onTableChange={handleTableChange}
                      {...paginationTableProps}
                    />
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          marginTop: '-10px',
                          fontSize: '13px'
                        }}
                      >
                        <PaginationTotalStandalone {...paginationProps} />
                        <PaginationListStandalone {...paginationProps} />
                      </div>
                    </div>
                  </div>
                )}
              </PaginationProvider>
            )}
          </div>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default Weapons;
