import { useEffect, useState } from 'react';
import ProductGallery from 'components/modules/e-commerce/ProductGallery';
import { Col, Row, Stack, Alert } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import LoadingOverlay from 'components/loading-overlay/LoadingOverlay';
import { getItem } from 'api/api';
import StockItemTable from './StockItemTable';

const breadcrumbItems = [
  {
    label: 'Weapons',
    url: '/weapons/weapons'
  },
  {
    label: 'Details',
    active: true
  }
];

function WeaponDetails() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [sku, setSku] = useState('');
  const [category_id, setCategory_id] = useState(null);
  const [location_id, setLocation_id] = useState(null);
  const [container_id, setContainer_id] = useState(null);
  const [description, setDescription] = useState('');
  const [status, setStatus] = useState(null);
  const [quantity, setQuantity] = useState(null);
  const [image, setImage] = useState([]);

  useEffect(() => {
    loadData(id);
  }, []);

  const loadData = async id => {
    setError([]);
    setLoading(true);

    getItem(id)
      .then(async response => {
        const data = response.data.data;

        setName(data.name);
        setSku(data.sku);
        setStatus(data.status.name);
        setQuantity(data.quantity);
        setImage([data.image]);

        setLocation_id({
          value: data.container.location.id,
          label: data.container.location.name
        });

        setContainer_id({
          value: data.container.id,
          label: data.container.name
        });

        setDescription(data.description);

        setCategory_id({ value: data.category.id, label: data.category.name });
      })
      .catch(err => {
        try {
          if (err.response.status === 401 || err.response.status === 400) {
            navigate('/sign-in');
          }
          setError(err.response.data.errors);
        } catch (ex) {
          setError(['Please try again later...']);
        }
      });

    setTimeout(function () {
      setLoading(false);
    }, 1000);
  };

  return (
    <div>
      <PageBreadcrumb items={breadcrumbItems} />
      <div className="mb-9">
        <div>
          {Object.entries(error).length > 0 &&
            error.map((e, index) => (
              <Alert variant="soft-danger" key={index}>
                {e}
              </Alert>
            ))}
        </div>

        {loading && <LoadingOverlay />}

        <Row className="g-5 mb-5 mb-lg-6">
          <Col xs={12} lg={6}>
            <ProductGallery images={image} />
            <div className="d-flex"></div>
          </Col>
          <Col xs={12} lg={6}>
            <Stack className="justify-content-between h-60">
              <div className="mb-2">
                <div className="d-flex flex-wrap">
                  <div className="me-2"></div>
                  <p className="text-primary fw-semi-bold mb-2"></p>
                </div>
                <h3 className="mb-3 lh-sm">
                  {`${name && name} -  ${category_id && category_id.label}`}
                </h3>
                <div className="d-flex flex-wrap align-items-start mb-3">
                  <span className="badge bg-success fs-9 rounded-pill me-2 fw-semi-bold">
                    {`#calibre ${sku && sku}`}
                  </span>
                  <p className="text-info-500 fw-bold mb-5 mb-lg-0">
                    {`Location at ${location_id && location_id.label} in ${
                      container_id && container_id.label
                    }`}
                  </p>
                </div>
                <div className="d-flex flex-wrap align-items-center">
                  <h1 className="me-3">{name && name}</h1>
                  {/* <p className="text-500 text-decoration-line-through fs-6 mb-0 me-3"></p>
                  <p className="text-warning-500 fw-bolder fs-6 mb-0"></p> */}
                </div>
                <p className="text-success fw-semi-bold fs-7 mb-2">
                  {status && status}
                </p>
                <p className="mb-2">{description && description}</p>
                <p className="text-danger-500 fw-bold mb-5 mb-lg-0">
                  Quantity : {quantity && quantity}
                </p>
              </div>

              <div>
                <div className="mb-3"></div>
                <div className="row g-3 g-sm-5 align-items-end">
                  <div className="col-12 col-sm-auto">
                    <p className="fw-semi-bold mb-2 text-900"> </p>
                  </div>
                  <div className="col-12 col-sm">
                    <p className="fw-semi-bold mb-2 text-900"></p>
                    <div className="d-flex justify-content-between align-items-end"></div>
                  </div>
                </div>
              </div>
            </Stack>
          </Col>
        </Row>

        <Row className="g-5 mb-5 mb-lg-8">
          <StockItemTable itemName={name && name} />
        </Row>
      </div>
    </div>
  );
}

export default WeaponDetails;
