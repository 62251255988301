import { useState, ChangeEvent } from 'react';
import { Link } from 'react-router-dom';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Button from 'components/base/Button';
import { Form, Alert } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { forgotPassword } from '../../../api/api';
import 'react-toastify/dist/ReactToastify.css';

interface FormData {
  email: string;
}

const ForgotPasswordForm = ({
  layout
}: {
  layout?: 'simple' | 'card' | 'split';
}) => {
  const [error, setError] = useState<string[]>([]);
  const [formData, setFormData] = useState<FormData>({
    email: ''
  });

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError([]);

    forgotPassword(formData.email)
      .then(response => {
        if (response.status === 200) {
          setFormData(prevFormData => ({
            ...prevFormData,
            email: ''
          }));
          toast.success('Reset link sent. Check your email.');
        }
      })
      .catch(err => {
        try {
          setError(err.response.data.errors);
        } catch (ex) {
          setError(['Please try again later...']);
        }
      });
  };

  return (
    <div className={classNames({ 'px-xxl-5': !(layout === 'split') })}>
      <div
        className={classNames('text-center', { 'mb-6': !(layout === 'split') })}
      >
        <h4 className="text-1000">Forgot your password?</h4>
        <p className="text-700 mb-5">
          Enter your email below and we will send <br className="d-sm-none" />
          you a reset link
        </p>

        {error &&
          error.map((e: string, index: number) => (
            <Alert variant="soft-danger" key={index}>
              {e}
            </Alert>
          ))}

        <Form
          onSubmit={handleSubmit}
          className="d-flex align-items-center mb-5"
        >
          <Form.Control
            type="email"
            id="email"
            name="email"
            className="flex-1"
            placeholder="Email"
            value={formData.email}
            onChange={handleInputChange}
            required
          />
          <Button
            variant="primary"
            className="ms-2"
            type="submit"
            endIcon={<FontAwesomeIcon icon={faChevronRight} className="ms-2" />}
          >
            Send
          </Button>
        </Form>
      </div>
      <div className="d-grid">
        <Button
          variant="primary"
          as={Link}
          to={`/sign-in`}
          startIcon={<FontAwesomeIcon icon={faAngleLeft} className="me-2" />}
        >
          Go to sign in page
        </Button>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ForgotPasswordForm;
