import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ProductVariant = ({ className }: { className?: string }) => {
  return (
    <div className={className}>
      <div className="d-flex gap-2 mb-2">
        <h5 className="mb-0 text-1000">Option 1</h5>
        <Link className="fw-bold fs-9" to="#!">
          Remove
        </Link>
      </div>
      <Form.Select className="mb-3">
        <option value="size">Size</option>
        <option value="color">Color</option>
        <option value="weight">Weight</option>
        <option value="smell">Smell</option>
      </Form.Select>
    </div>
  );
};

export default ProductVariant;
