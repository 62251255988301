import { useState, ChangeEvent, useEffect } from 'react';
import { faKey, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Form, Row, Button, Alert } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { signIn, signOut } from '../../../api/api';

interface FormData {
  email: string;
  password: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SignInForm = ({ layout }: { layout: 'simple' | 'card' | 'split' }) => {
  const navigate = useNavigate();

  useEffect(() => {
    userSignOut();
  }, []);

  const [error, setError] = useState<string[]>([]);
  const [formData, setFormData] = useState<FormData>({
    email: '',
    password: ''
  });

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const userSignOut = () => {
    const refToken = localStorage.getItem('st_john_refresh_token');

    if (refToken === undefined || refToken === null || refToken === '') {
      clearLocalStorage();
      return;
    }

    signOut({ refresh_token: refToken })
      .then(response => {
        if (response.status === 204) {
          clearLocalStorage();
        }
      })
      .catch(() => {});
  };

  const clearLocalStorage = () => {
    localStorage.removeItem('st_john_token');
    localStorage.removeItem('st_john_refresh_token');
    localStorage.removeItem('st_john_user_name');
    localStorage.removeItem('st_john_user_group');
  };

  const handleSubmit = (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError([]);

    signIn(formData)
      .then(response => {
        localStorage.setItem('st_john_token', response.data.data.token);
        localStorage.setItem(
          'st_john_refresh_token',
          response.data.data.refreshToken
        );
        localStorage.setItem(
          'st_john_user_name',
          `${response.data.data.user.first_name} ${response.data.data.user.last_name} `
        );

        localStorage.setItem(
          'st_john_user_group',
          `${response.data.data.user.user_group.name}`
        );

        routeToDashboard();
      })
      .catch(err => {
        try {
          setError(err.response.data.errors);
        } catch (ex) {
          setError(['Please try again later...']);
        }
      });
  };

  const routeToDashboard = () => {
    navigate('/');
  };

  return (
    <>
      <div className="text-center mb-7">
        <h3 className="text-1000">Sign In</h3>
        <p className="text-700">Get access to your account</p>
      </div>
      <div className="position-relative">
        <hr className="bg-200 mt-5 mb-4" />
        <div className="divider-content-center">use email</div>
      </div>

      {Object.entries(error).length > 0 &&
        error.map((e: string, index: number) => (
          <Alert variant="soft-danger" key={index}>
            {e}
          </Alert>
        ))}

      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="email">Email address</Form.Label>
          <div className="form-icon-container">
            <Form.Control
              id="email"
              type="email"
              name="email"
              className="form-icon-input"
              placeholder="name@example.com"
              value={formData.email}
              onChange={handleInputChange}
            />
            <FontAwesomeIcon
              icon={faUser}
              className="text-900 fs-9 form-icon"
            />
          </div>
        </Form.Group>
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="password">Password</Form.Label>
          <div className="form-icon-container">
            <Form.Control
              id="password"
              type="password"
              name="password"
              className="form-icon-input"
              placeholder="Password"
              value={formData.password}
              onChange={handleInputChange}
            />
            <FontAwesomeIcon icon={faKey} className="text-900 fs-9 form-icon" />
          </div>
        </Form.Group>
        <Row className="flex-between-center mb-7">
          <Col xs="auto"></Col>
          <Col xs="auto">
            <Link to={`/forgot-password/`} className="fs-9 fw-semi-bold">
              Forgot Password?
            </Link>
          </Col>
        </Row>
        <Button variant="primary" className="w-100 mb-3" type="submit">
          Sign In
        </Button>
      </Form>
    </>
  );
};

export default SignInForm;
