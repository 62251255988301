import API from './index';

let config = {
  headers: { authorization: localStorage.getItem('st_john_token') }
};

//Auth **
export const signIn = authData => {
  //authData.login_type = "web";
  return API.post('/auth/signin', authData);
};

export const forgotPassword = email => {
  return API.post('/auth/forgot-password', { email: email });
};

export const resetPassword = resetDetails => {
  return API.post('/auth/reset-password', resetDetails);
};

export const getNewToken = refreshToken => {
  return API.post('/auth/token', refreshToken);
};

// Dashboard

export const getDashboard = () => {
  return API.get('/dashboard', config);
};

// Items

export const getItems = (
  page,
  limit,
  statusId,
  sortBy,
  location_id,
  container_id,
  q,
  category_id
) => {
  config.params = {
    page: page,
    limit: limit,
    status_id: statusId,
    location_id: location_id,
    container_id: container_id,
    sortBy: sortBy,
    q: q,
    category_id: category_id
  };

  return API.get('/items', config);
};

export const getItem = id => {
  return API.get('/items/' + id, config);
};

export const deleteItem = id => {
  return API.delete('/items/' + id, config);
};

export const createItem = data => {
  return API.post('/items/', data, config);
};

export const archiveItem = (id, stockId) => {
  return API.post(`/items/${id}/stocks/${stockId}/archive`, config);
};

export const patchItem = (id, data) => {
  return API.patch('/items/' + id, data, config);
};

export const getItemStocks = id => {
  return API.get(`/items/${id}/stocks`, config);
};

export const createItemStocks = (id, data) => {
  return API.post(`/items/${id}/stocks`, data, config);
};

export const patchItemStock = (id, stockId, data) => {
  return API.patch(`/items/${id}/stocks/${stockId}`, data, config);
};

export const deleteItemStocks = (itemId, stockId) => {
  return API.delete(`/items/${itemId}/stocks/${stockId}`, config);
};

export const searchItem = (q, page, limit) => {
  config.params = { q: q, page: page, limit: limit };
  return API.get(`/items/search/stocks`, config);
};

export const searchStockByEpc = epc => {
  return API.get(`/items/search/stocks/${epc}`, config);
};

export const patchImage = (id, data) => {
  return API.patch('/items/' + id + '/image', data, config);
};

export const searchItemMetadata = (key, q) => {
  config.params = { key: key, q: q };
  return API.get(`/items/metadata/${key}`, config);
};

export const getStockLogs = (page, limit, sortBy, id, stockId) => {
  config.params = {
    page: page,
    limit: limit,
    sortBy: sortBy,
    id: id,
    stock_id: stockId
  };
  return API.get(`/items/${id}/stocks/${stockId}/logs`, config);
};

//Categories

export const getCategories = (page, limit, sortBy) => {
  config.params = { page: page, limit: limit, sortBy: sortBy };
  return API.get('/categories', config);
};

export const getSubCategories = (page, limit, sortBy) => {
  config.params = { page: page, limit: limit, sortBy: sortBy };
  return API.get('/categories/subcategories', config);
};

export const getSubCategoriesById = (id, page, limit, sortBy) => {
  config.params = { page: page, limit: limit, sortBy: sortBy };
  return API.get(`/categories/${id}/subcategories`, config);
};

export const getCategory = id => {
  return API.get('/categories/' + id, config);
};

export const createCategory = data => {
  return API.post('/categories/', data, config);
};

export const patchCategory = (id, data) => {
  console.log(data);
  return API.patch('/categories/' + id, data, config);
};

export const deleteCategory = id => {
  return API.delete('/categories/' + id, config);
};

// Containers

export const getContainers = (page, limit, sortBy) => {
  config.params = { page: page, limit: limit, sortBy: sortBy };
  return API.get('/containers', config);
};

export const getContainer = id => {
  return API.get('/containers/' + id, config);
};

export const createContainer = data => {
  return API.post('/containers/', data, config);
};

export const patchContainer = (id, data) => {
  return API.patch('/containers/' + id, data, config);
};

export const deleteContainer = id => {
  return API.delete('/containers/' + id, config);
};

// Locations

export const getLocations = (page, limit, sortBy) => {
  config.params = { page: page, limit: limit, sortBy: sortBy };
  return API.get('/locations', config);
};

export const getLocation = id => {
  return API.get('/locations/' + id, config);
};

export const createLocation = data => {
  return API.post('/locations/', data, config);
};

export const patchLocation = (id, data) => {
  return API.patch('/locations/' + id, data, config);
};

export const deleteLocation = id => {
  return API.delete('/locations/' + id, config);
};

export const getContainerByLocationId = id => {
  return API.get(`/locations/${id}/containers/`, config);
};

// Item Units

export const getItemUnits = () => {
  return API.get('/item-units', config);
};

// User

export const getUsers = (page, limit, sortBy) => {
  config.params = { page: page, limit: limit, sortBy: sortBy };
  return API.get('/users', config);
};

export const getUser = id => {
  return API.get('/users/' + id, config);
};

export const createUser = data => {
  return API.post('/users', data, config);
};

export const patchUser = (id, data) => {
  return API.patch('/users/' + id, data, config);
};

export const patchUserPassword = (id, data) => {
  return API.patch(`/users/${id}/password`, data, config);
};

export const patchUserPin = (id, data) => {
  return API.patch(`/users/${id}/login_pin`, data, config);
};

export const deleteUser = id => {
  return API.delete('/users/' + id, config);
};

export const getUseLogs = (id, page, limit, sortBy) => {
  config.params = { page: page, limit: limit, sortBy: sortBy };
  return API.get('/users/' + id + '/logs', config);
};

// Orders

export const getOrders = (page, limit, sortBy, fromDate, toDate) => {
  config.params = {
    page: page,
    limit: limit,
    sortBy: sortBy,
    from_date: fromDate,
    to_date: toDate
  };
  return API.get('/orders', config);
};

export const createOrder = data => {
  return API.post('/orders', data, config);
};

// Inventory

export const getInventory = (page, limit, status, sortBy, q, category_id) => {
  config.params = {
    page: page,
    limit: limit,
    status_id: status,
    sortBy: sortBy,
    q: q,
    category_id: category_id
  };
  return API.get('/inventory/', config);
};

export const getInventoryLogs = (page, limit, from_data, to_data, sortBy) => {
  config.params = {
    page: page,
    limit: limit,
    from_date: from_data,
    to_date: to_data,
    sortBy: sortBy
  };
  return API.get('/inventory/logs', config);
};

export const getInventoryLogDetails = (id, page, limit, status, sortBy) => {
  config.params = {
    page: page,
    limit: limit,
    status: status,
    sortBy: sortBy
  };
  return API.get('/inventory/logs/' + id, config);
};

export const getArchived = (page, limit, sortBy) => {
  config.params = {
    page: page,
    limit: limit,
    sortBy: sortBy
  };
  return API.get('/inventory/archived', config);
};

// User Roles

export const getUserRoles = () => {
  return API.get('/roles', config);
};

// User Groups

export const getUserGroups = (page, limit, sortBy) => {
  config.params = { page: page, limit: limit, sortBy: sortBy };
  return API.get('/groups', config);
};

export const getUserGroup = id => {
  return API.get('/groups/' + id, config);
};

export const createUserGroup = data => {
  return API.post('/groups/', data, config);
};

export const patchUserGroup = (id, data) => {
  return API.patch('/groups/' + id, data, config);
};

export const deleteUserGroup = id => {
  return API.delete('/groups/' + id, config);
};

// Me
export const signOut = data => {
  return API.post('/me/logout', data, config);
};

export const patchPassword = data => {
  return API.patch('/me/password', data, config);
};

export const getUserProfile = () => {
  return API.get('/me', config);
};

// Reports
export const getNotReturnedWeapons = (page, limit, sortBy, q) => {
  config.params = { page: page, limit: limit, sortBy: sortBy, q: q };
  return API.get('/items/stocks/not_returned', config);
};

// Readers
export const getUnauthorizedAccessLogs = (
  page,
  limit,
  sortBy,
  statusId,
  from_date,
  to_date
) => {
  config.params = {
    page: page,
    limit: limit,
    sortBy: sortBy,
    status_id: statusId,
    from_date: from_date,
    to_date: to_date
  };
  return API.get('/readers/logs', config);
};

export const getReaders = (page, limit, sortBy) => {
  config.params = { page: page, limit: limit, sortBy: sortBy };
  return API.get('/readers', config);
};

export const getReader = id => {
  return API.get(`/readers/${id}`, config);
};

export const patchReader = (id, data) => {
  return API.patch(`/readers/${id}`, data, config);
};

export const deleteReaderLogs = () => {
  return API.delete(`/readers/logs`, config);
};
