import { useState, useEffect, useRef } from 'react';
import Button from 'components/base/Button';
import { Col, Row, Alert, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import LoadingOverlay from 'components/loading-overlay/LoadingOverlay';
import { createUserGroup, getUserRoles } from 'api/api';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import _ from 'lodash';

const breadcrumbItems = [
  {
    label: 'User group',
    url: '/user-group/user-group'
  },
  {
    label: 'Add',
    active: true
  }
];

function AddUserGroup() {
  const navigate = useNavigate();
  const nameInputRef = useRef(null);
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userRoles, setUserRoles] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [name, setName] = useState(null);
  const [numberOfWeapons, setNumberOfWeapons] = useState(null);
  const [weaponAccess, setWeaponAccess] = useState(false);
  const [systemAccess, setSystemAccess] = useState(false);
  const [flattenedData, setFlattenedData] = useState([]);

  useEffect(() => {
    if (nameInputRef.current) {
      nameInputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    setError([]);
    setLoading(true);

    getUserRoles()
      .then(response => {
        setUserRoles(response.data.data);
        const flattenedData = flattenRoles(response.data.data);
        setFlattenedData(flattenedData);
      })
      .catch(err => {
        try {
          setError(err.response.data.errors);
        } catch (ex) {
          setError(['Please try again later...']);
        }
      });

    setTimeout(function () {
      setLoading(false);
    }, 1000);
  };

  const flattenRoles = rolesData => {
    return _.flattenDeep(
      rolesData.map(role => [
        {
          role: role.role,
          is_activity: role.is_activity,
          ...(_.omit(role, ['roles']) || {})
        },
        flattenRoles(role.roles || [])
      ])
    );
  };

  const handleSubmit = e => {
    e.preventDefault();
    setError([]);
    setLoading(true);

    if (weaponAccess === false && systemAccess === false) {
      setError(['Select either Weapon Access or System Access']);
      setLoading(false);
      return;
    }

    const data = {
      name: name,
      system_access: systemAccess,
      weapon_access: weaponAccess,
      no_of_weapons: numberOfWeapons,
      roles: selectedCheckboxes
    };

    createUserGroup(data)
      .then(response => {
        if (response.status === 200) navigate('/user-group/user-group');
      })
      .catch(err => {
        console.log(err.response);
        try {
          setError(err.response.data.errors);
        } catch (error) {
          setError(['Please try again later...']);
        }
      });

    setTimeout(function () {
      setLoading(false);
    }, 1000);
  };

  // eslint-disable-next-line react/prop-types
  const CheckboxList = ({ data }) => {
    const renderCheckboxes = items => {
      return items.map((item, index) => {
        const isChecked = selectedCheckboxes.includes(item.id);
        if (item.is_activity) {
          return (
            <>
              <div key={index} style={{ width: '250px', marginRight: '10px' }}>
                <Form.Group key={item.id}>
                  <Form.Check
                    type="checkbox"
                    id={`checkbox_${item.id}`}
                    label={item.role}
                    checked={isChecked}
                    onChange={() => handleCheckboxChange(item.id)}
                  />
                </Form.Group>
              </div>
            </>
          );
        } else {
          // Render subheading and checkboxes in a new row for non-activities
          return (
            <>
              <Row key={index}>
                <div>
                  <Col>{<h6>{item.role}</h6>}</Col>
                </div>
              </Row>
              <div key={index} style={{ marginLeft: '20px' }}>
                <Row key={index}>{renderCheckboxes(item.roles)}</Row>
              </div>
            </>
          );
        }
      });
    };

    return <>{renderCheckboxes(data)}</>;
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    const allCheckboxes = collectAllIds(userRoles);

    setSelectedCheckboxes(prevSelected =>
      selectAll
        ? prevSelected.filter(id => !allCheckboxes.includes(id))
        : allCheckboxes
    );
  };

  const collectAllIds = items => {
    let allIds = [];
    items.forEach(item => {
      if (item.is_activity) {
        allIds.push(item.id);
      }
      if (item.roles && item.roles.length > 0) {
        allIds = [...allIds, ...collectAllIds(item.roles)];
      }
    });
    return allIds;
  };

  const handleCheckboxChange = itemId => {
    let dependencyIdsArray = getAllDependenciesById(itemId);
    dependencyIdsArray.push(itemId);

    const updatedCheckboxes = selectedCheckboxes.some(id =>
      dependencyIdsArray.includes(id)
    )
      ? selectedCheckboxes.filter(id => !dependencyIdsArray.includes(id))
      : [...selectedCheckboxes, ...dependencyIdsArray];

    setSelectedCheckboxes(updatedCheckboxes);
  };

  const getAllDependenciesById = (id, allDependencies = new Set()) => {
    const item = flattenedData.find(item => item.id === id);

    if (item) {
      item.dependencies.forEach(dependencyId => {
        allDependencies.add(dependencyId);
        getAllDependenciesById(dependencyId, allDependencies);
      });
    }

    return Array.from(allDependencies);
  };

  return (
    <div>
      <PageBreadcrumb items={breadcrumbItems} />
      <div className="mb-9">
        <div className="d-flex flex-wrap gap-3 flex-between-end mb-5">
          <div>
            <h2 className="mb-2">Add a User Group</h2>
            <h5 className="text-700 fw-semi-bold">Add a new user group</h5>
          </div>
        </div>
        <Row className="g-5">
          <Col xs={12} xl={12}>
            <div>
              <div>
                {Object.entries(error).length > 0 &&
                  error.map((e, index) => (
                    <Alert variant="soft-danger" key={index}>
                      {e}
                    </Alert>
                  ))}
              </div>

              {loading && <LoadingOverlay />}

              {/* <CheckboxList data={userRoles} /> */}
              <form onSubmit={handleSubmit}>
                <Row>
                  <Col xs={12} xl={4}>
                    <Form.Group>
                      <div style={{ fontSize: '14px' }}>Name*</div>
                      <Form.Control
                        style={{ marginBottom: '10px' }}
                        type="text"
                        value={name}
                        onChange={e => setName(e.target.value)}
                        ref={nameInputRef}
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} xl={4}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Form.Check
                        style={{ marginRight: '20px', marginBottom: '10px' }}
                        type="checkbox"
                        label="Kiosk Access"
                        checked={weaponAccess}
                        onChange={() => setWeaponAccess(!weaponAccess)}
                      />

                      <Form.Check
                        type="checkbox"
                        label="Is System Access"
                        checked={systemAccess}
                        onChange={() => setSystemAccess(!systemAccess)}
                      />
                    </div>
                  </Col>
                </Row>

                {weaponAccess && (
                  <Row>
                    <Col>
                      <div style={{ fontSize: '14px' }}>Number of Weapons*</div>
                      <Form.Control
                        style={{ marginBottom: '10px', width: '150px' }}
                        type="number"
                        value={numberOfWeapons}
                        onChange={e =>
                          setNumberOfWeapons(Math.max(1, e.target.value))
                        }
                        ref={nameInputRef}
                        required
                      />
                    </Col>
                  </Row>
                )}

                {systemAccess && (
                  <>
                    <Row>
                      <Col xs={12} xl={4}>
                        <Form.Group>
                          <Form.Check
                            style={{ marginBottom: '20px' }}
                            type="checkbox"
                            id="selectAllCheckbox"
                            label="Select All"
                            checked={selectAll}
                            onChange={e => handleSelectAll(e)}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <CheckboxList data={userRoles} />
                    </Row>
                  </>
                )}

                <Row style={{ marginTop: '5px' }}>
                  <Col xs={12} xl={4}>
                    <Button variant="primary" type="submit">
                      Submit
                    </Button>{' '}
                    <Button
                      variant="phoenix-secondary"
                      type="button"
                      onClick={() => navigate('/user-group/user-group')}
                    >
                      Discard
                    </Button>
                  </Col>
                </Row>
              </form>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default AddUserGroup;
