import { useState, useEffect } from 'react';
import Button from 'components/base/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert, Form, Row, Col } from 'react-bootstrap';
import LoadingOverlay from 'components/loading-overlay/LoadingOverlay';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { deleteUser, getUser, getUserGroups } from 'api/api';

const breadcrumbItems = [
  {
    label: 'Users',
    url: '/users/users'
  },
  {
    label: 'Delete',
    active: true
  }
];

const DeleteUser = () => {
  const navigate = useNavigate();
  const animatedComponents = makeAnimated();
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [userGroups, setUserGroup] = useState([]);
  const [userGroupId, setUserGroupId] = useState(null);
  const [idNumber, setIdNumber] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    loadData(id);
  }, [id]);

  useEffect(() => {
    loadUserRoles();
  }, []);

  const loadUserRoles = () => {
    setError([]);
    setLoading(true);

    getUserGroups()
      .then(response => {
        const data = response.data.data;
        setUserGroup(data.data);
      })
      .catch(err => {
        try {
          setError(err.response.data.errors);
        } catch (ex) {
          console.log(ex);
          setError(['Please try again later...']);
        }
      });

    setTimeout(function () {
      setLoading(false);
    }, 1000);
  };

  const loadData = id => {
    setError([]);
    setLoading(true);

    getUser(id)
      .then(response => {
        setFirstName(response.data.data.first_name);
        setLastName(response.data.data.last_name);
        setIdNumber(response.data.data.id_no);
        setUserGroupId({
          value: response.data.data.user_group.id,
          label: response.data.data.user_group.name
        });
      })
      .catch(err => {
        try {
          setError(err.response.data.errors);
        } catch (ex) {
          setError(['Please try again later...']);
        }
      });

    setTimeout(function () {
      setLoading(false);
    }, 1000);
  };

  const handleSubmit = e => {
    e.preventDefault();

    setError([]);
    setLoading(true);

    deleteUser(id)
      .then(response => {
        if (response.status === 204) navigate('/users/users');
      })
      .catch(err => {
        try {
          setError(err.response.data.errors);
        } catch (error) {
          setError(['Please try again later...']);
        }
      });

    setTimeout(function () {
      setLoading(false);
    }, 1000);
  };

  const handleUserGroupChange = e => {
    setUserGroupId(e);
  };

  return (
    <div>
      <PageBreadcrumb items={breadcrumbItems} />
      <div className="mb-9">
        <div className="d-flex flex-wrap gap-3 flex-between-end mb-5">
          <div>
            <h2 className="mb-2">Delete User</h2>
            <h5 className="text-700 fw-semi-bold">Delete a user</h5>
          </div>
        </div>
        <div>
          {Object.entries(error).length > 0 &&
            error.map((e, index) => (
              <Alert variant="soft-danger" key={index}>
                {e}
              </Alert>
            ))}
        </div>

        {loading && <LoadingOverlay />}

        <div>
          <form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12} xl={4}>
                <label>First Name*</label>
                <Form.Control
                  className="mb-2"
                  type="text"
                  value={firstName}
                  onChange={e => setFirstName(e.target.value)}
                  disabled={true}
                />
              </Col>

              <Col xs={12} xl={4}>
                <label>Last Name*</label>
                <Form.Control
                  className="mb-2"
                  type="text"
                  value={lastName}
                  onChange={e => setLastName(e.target.value)}
                  disabled={true}
                />
              </Col>
            </Row>

            <Row>
              <Col xs={12} xl={4}>
                <label>ID number*</label>
                <Form.Control
                  className="mb-2"
                  type="text"
                  value={idNumber}
                  onChange={e => setIdNumber(e.target.value)}
                  disabled={true}
                />
              </Col>

              <Col xs={12} xl={4}>
                <label>User Group*</label>
                <Select
                  className="mb-2"
                  classNamePrefix="react-select"
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  value={userGroupId}
                  options={
                    userGroups &&
                    userGroups.map(c => ({
                      value: c.id,
                      label: c.name
                    }))
                  }
                  onChange={e => handleUserGroupChange(e)}
                  isDisabled={true}
                  name="user_group"
                  menuPlacement="auto"
                />
              </Col>
            </Row>

            <Row>
              <Col xs={12} xl={4}>
                <Button className="mb-2" variant="danger" type="submit">
                  Delete
                </Button>{' '}
                <Button
                  className="mb-2"
                  variant="phoenix-secondary"
                  type="button"
                  onClick={() => navigate('/users/users')}
                >
                  Discard
                </Button>
              </Col>
            </Row>
          </form>
        </div>
      </div>
    </div>
  );
};

export default DeleteUser;
