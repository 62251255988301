import { useState, useRef, useEffect } from 'react';
import Button from 'components/base/Button';
import { useNavigate } from 'react-router-dom';
import { Alert, Form, Row, Col } from 'react-bootstrap';
import LoadingOverlay from 'components/loading-overlay/LoadingOverlay';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import Autosuggest from 'react-autosuggest';
import {
  getItemUnits,
  createItem,
  getSubCategories,
  getLocations,
  getContainerByLocationId,
  searchItemMetadata
} from 'api/api';

const breadcrumbItems = [
  {
    label: 'Weapons',
    url: '/weapons/weapons'
  },
  {
    label: 'Add',
    active: true
  }
];

const AddWeapon = () => {
  const navigate = useNavigate();
  const nameInputRef = useRef(null);
  const animatedComponents = makeAnimated();
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState(false);
  const [subCategoryName, setSubCategoryName] = useState([]);
  const [containerName, setContainerName] = useState([]);
  const [unitNames, setUnitNames] = useState([]);
  const [locationName, setLocationName] = useState([]);
  const [name, setName] = useState('');
  const [sku, setSku] = useState('');
  const [category_id, setCategory_id] = useState(null);
  const [container_id, setContainer_id] = useState(null);
  const [item_unit_id, setItem_unit_id] = useState(null);
  const [description, setDescription] = useState('');
  const [quantity, setQuantity] = useState(null);
  const [rows, setRows] = useState([]);
  const [suggestionsListMake, setSuggestionsListMake] = useState([]);
  const [suggestionsListCalibre, setSuggestionsListCalibre] = useState([]);

  useEffect(() => {
    if (nameInputRef.current) {
      nameInputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    loadLocations();
    loadItemUnits();
    loadSubCategories();
  }, []);

  const loadSubCategories = () => {
    setError([]);
    setLoading(true);

    getSubCategories(1, 0, 'name:asc')
      .then(response => {
        const data = response.data.data.data;
        setSubCategoryName(data);
      })
      .catch(err => {
        try {
          setError(err.response.data.errors);
        } catch (ex) {
          setError(['Please try again later...']);
        }
      });

    setTimeout(function () {
      setLoading(false);
    }, 1000);
  };

  const loadLocations = () => {
    setError([]);
    setLoading(true);

    getLocations()
      .then(response => {
        const data = response.data.data.data;
        setLocationName(data);
      })
      .catch(err => {
        try {
          setError(err.response.data.errors);
        } catch (ex) {
          setError(['Please try again later...']);
        }
      });

    setTimeout(function () {
      setLoading(false);
    }, 1000);
  };

  const loadContainers = id => {
    setError([]);
    setLoading(true);

    getContainerByLocationId(id)
      .then(response => {
        const data = response.data.data;
        setContainerName(data);
      })
      .catch(err => {
        try {
          setError(err.response.data.errors);
        } catch (ex) {
          console.log(ex);
          setError(['Please try again later...']);
        }
      });

    setTimeout(function () {
      setLoading(false);
    }, 1000);
  };

  const loadItemUnits = () => {
    setError([]);
    setLoading(true);

    getItemUnits()
      .then(response => {
        const data = response.data.data;
        setUnitNames(data);
      })
      .catch(err => {
        try {
          setError(err.response.data.errors);
        } catch (ex) {
          console.log(ex);
          setError(['Please try again later...']);
        }
      });

    setTimeout(function () {
      setLoading(false);
    }, 1000);
  };

  const handleSubmit = e => {
    e.preventDefault();

    setError([]);
    setLoading(true);

    const formData = {
      name: name,
      sku: sku,
      category_id: category_id.value,
      description: description,
      container_id: container_id.value,
      item_unit_id: item_unit_id,
      quantity: quantity,
      stocks: rows
    };

    createItem(formData)
      .then(response => {
        if (response.status === 200) navigate('/weapons/weapons');
      })
      .catch(err => {
        try {
          setError(err.response.data.errors);
        } catch (error) {
          setError(['Please try again later...']);
        }
      });

    setTimeout(function () {
      setLoading(false);
    }, 1000);
  };

  const handleChangeLocation = e => {
    loadContainers(e.value);
    setContainer_id(null);
    // setLocation_id(e.value);
  };

  const handleUnitInputChange = e => {
    setQuantity(e);
    generateRows(parseInt(e, 10));
  };

  const generateRows = count => {
    const newRows = [];
    for (let i = 0; i < count; i++) {
      newRows.push({ epc: '', serial_number: '', cfrn: '', tid: '' });
    }
    setRows(newRows);
  };

  const handleEpcChange = (event, index) => {
    const updatedRows = [...rows];
    updatedRows[index].epc = event.target.value;
    setRows(updatedRows);
  };

  const handleSerialNumberChange = (event, index) => {
    const updatedRows = [...rows];
    updatedRows[index].serial_number = event.target.value;
    setRows(updatedRows);
  };

  const handleCfrnChange = (event, index) => {
    const updatedRows = [...rows];
    updatedRows[index].cfrn = event.target.value;
    setRows(updatedRows);
  };

  const handleTidChange = (event, index) => {
    const updatedRows = [...rows];
    updatedRows[index].tid = event.target.value;
    setRows(updatedRows);
  };

  const customStyles = {
    singleValue: provided => ({
      ...provided,
      fontSize: '12px'
    })
  };

  // Autosuggest functions Make
  const onSuggestionsFetchRequestedMake = ({ value }) => {
    searchItemMetadata('make', value.trim())
      .then(response => {
        if (response.data.data.make !== undefined)
          setSuggestionsListMake(response.data.data.make);
        else setSuggestionsListMake([]);
      })
      .catch(err => {
        try {
          setError(err.response.data.errors);
          setSuggestionsListMake([]);
        } catch (error) {
          setError(['Please try again later...']);
          setSuggestionsListMake([]);
        }
      });
  };

  const onSuggestionsClearRequestedMake = () => {
    setSuggestionsListMake([]);
  };

  const onChangeMake = (event, { newValue }) => {
    setName(newValue);
  };

  const getSuggestionValueMake = suggestion => suggestion;

  const renderSuggestionMake = suggestion => <div>{suggestion}</div>;

  const inputPropsMake = {
    placeholder: 'Type a Make',
    value: name,
    className: 'form-control',
    onChange: onChangeMake,
    required: true
  };

  // Autosuggest functions Calibre
  const onSuggestionsFetchRequestCalibre = ({ value }) => {
    searchItemMetadata('calibre', value.trim())
      .then(response => {
        if (response.data.data.calibre !== undefined)
          setSuggestionsListCalibre(response.data.data.calibre);
        else setSuggestionsListCalibre([]);
      })
      .catch(err => {
        try {
          setError(err.response.data.errors);
          setSuggestionsListCalibre([]);
        } catch (error) {
          setError(['Please try again later...']);
          setSuggestionsListCalibre([]);
        }
      });
  };

  const onSuggestionsClearRequestedCalibre = () => {
    setSuggestionsListCalibre([]);
  };

  const onChangeCalibre = (event, { newValue }) => {
    setSku(newValue);
  };

  const getSuggestionValueCalibre = suggestion => suggestion;

  const renderSuggestionCalibre = suggestion => <div>{suggestion}</div>;

  // Autosuggest input props
  const inputPropsCalibre = {
    placeholder: 'Type a Calibre',
    value: sku,
    className: 'form-control',
    onChange: onChangeCalibre,
    required: true
  };

  return (
    <div>
      <PageBreadcrumb items={breadcrumbItems} />
      <div className="mb-9">
        <div className="d-flex flex-wrap gap-3 flex-between-end mb-5">
          <div>
            <h2 className="mb-2">Add Weapon</h2>
            <h5 className="text-700 fw-semi-bold">Add a new weapon</h5>
          </div>
        </div>
        <div>
          {Object.entries(error).length > 0 &&
            error.map((e, index) => (
              <Alert variant="soft-danger" key={index}>
                {e}
              </Alert>
            ))}
        </div>

        {loading && <LoadingOverlay />}

        <div>
          <form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12} xl={4}>
                <label>Make*</label>
                <Autosuggest
                  suggestions={suggestionsListMake}
                  onSuggestionsFetchRequested={onSuggestionsFetchRequestedMake}
                  onSuggestionsClearRequested={onSuggestionsClearRequestedMake}
                  getSuggestionValue={getSuggestionValueMake}
                  renderSuggestion={renderSuggestionMake}
                  inputProps={inputPropsMake}
                />
              </Col>

              <Col xs={12} xl={4}>
                <label>Model*</label>
                <Form.Control
                  className="mb-2"
                  type="text"
                  value={description}
                  onChange={e => setDescription(e.target.value)}
                  required
                />
              </Col>
            </Row>

            <Row>
              <Col xs={12} xl={4}>
                <label>Category*</label>
                <Select
                  className="mb-2"
                  classNamePrefix="react-select"
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  options={
                    subCategoryName &&
                    subCategoryName.map(c => ({
                      value: c.id,
                      label: c.name
                    }))
                  }
                  value={category_id}
                  onChange={e => setCategory_id(e)}
                  required
                  name="sub_category"
                  menuPlacement="auto"
                  styles={customStyles}
                />
              </Col>

              <Col xs={12} xl={4}>
                <label>Calibre*</label>
                <Autosuggest
                  suggestions={suggestionsListCalibre}
                  onSuggestionsFetchRequested={onSuggestionsFetchRequestCalibre}
                  onSuggestionsClearRequested={
                    onSuggestionsClearRequestedCalibre
                  }
                  getSuggestionValue={getSuggestionValueCalibre}
                  renderSuggestion={renderSuggestionCalibre}
                  inputProps={inputPropsCalibre}
                />
              </Col>
            </Row>

            <Row>
              <Col xs={12} xl={4}>
                <label>Location*</label>
                <Select
                  className="mb-2"
                  classNamePrefix="react-select"
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  options={
                    locationName &&
                    locationName.map(c => ({
                      value: c.id,
                      label: c.name
                    }))
                  }
                  onChange={e => handleChangeLocation(e)}
                  required
                  name="location"
                  menuPlacement="auto"
                  styles={customStyles}
                />
              </Col>

              <Col xs={12} xl={4}>
                <label>Container*</label>
                <Select
                  className="mb-2"
                  classNamePrefix="react-select"
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  options={
                    containerName &&
                    containerName.map(c => ({
                      value: c.id,
                      label: c.name
                    }))
                  }
                  value={container_id}
                  onChange={e => setContainer_id(e)}
                  required
                  name="container"
                  menuPlacement="auto"
                  styles={customStyles}
                />
              </Col>
            </Row>

            <Row>
              <Col xs={12} xl={4}>
                <label>Unit*</label>
                <Select
                  className="mb-2"
                  classNamePrefix="react-select"
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  options={
                    unitNames &&
                    unitNames.map(c => ({
                      value: c.id,
                      label: c.unit
                    }))
                  }
                  onChange={e => setItem_unit_id(e.value)}
                  required
                  name="unit"
                  menuPlacement="auto"
                  styles={customStyles}
                />
              </Col>
              <Col xs={12} xl={4}>
                <label>Quantity*</label>
                <Form.Control
                  className="mb-2"
                  type="number"
                  value={quantity}
                  min="0"
                  step="1"
                  onChange={e => handleUnitInputChange(e.target.value)}
                  required
                />
              </Col>
            </Row>

            {rows.map((row, index) => (
              <>
                <Row key={index}>
                  <Col xs={12} xl={4}>
                    <label>EPC*</label>
                    <Form.Control
                      className="mb-2"
                      type="text"
                      value={row.epc}
                      onChange={e => handleEpcChange(e, index)}
                      ref={nameInputRef}
                      required
                    />
                  </Col>

                  <Col xs={12} xl={4}>
                    <label>Serial Number*</label>
                    <Form.Control
                      className="mb-2"
                      type="text"
                      value={row.serial_number}
                      onChange={e => handleSerialNumberChange(e, index)}
                      ref={nameInputRef}
                      required
                    />
                  </Col>
                </Row>
                <Row key={index}>
                  <Col xs={12} xl={4}>
                    <label>Canadian Firearms Reg Nr</label>
                    <Form.Control
                      className="mb-2"
                      type="text"
                      value={row.cfrn}
                      onChange={e => handleCfrnChange(e, index)}
                      ref={nameInputRef}
                      required
                    />
                  </Col>

                  <Col xs={12} xl={4}>
                    <label>TID</label>
                    <Form.Control
                      className="mb-2"
                      type="text"
                      value={row.tin}
                      onChange={e => handleTidChange(e, index)}
                      ref={nameInputRef}
                      required
                    />
                  </Col>
                </Row>
              </>
            ))}

            <Row>
              <Col xs={12} xl={4}>
                <Button variant="primary" type="submit" className="mb-2">
                  Submit
                </Button>{' '}
                <Button
                  className="mb-2"
                  variant="phoenix-secondary"
                  type="button"
                  onClick={() => navigate('/weapons/weapons')}
                >
                  Discard
                </Button>
              </Col>
            </Row>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddWeapon;
