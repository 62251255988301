import { useEffect, useState } from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
//import SearchBox from 'components/common/SearchBox';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { useNavigate } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationTotalStandalone,
  PaginationProvider,
  PaginationListStandalone
} from 'react-bootstrap-table2-paginator';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Dropdown, Alert, Spinner } from 'react-bootstrap';
import Badge from 'components/base/Badge';
import FilterTab from 'components/common/FilterTab';
import moment from 'moment';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import useAdvanceTable from 'hooks/useAdvanceTable';
import FeatherIcon from 'feather-icons-react';
import { CustomSortCaret } from 'helpers/ui-utils';
import { getSubCategories } from 'api/api';

const SubCategory = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [error, setError] = useState([]);
  const [total, setTotal] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [sortColumn, setSortColumn] = useState('asc');
  const [columnName, setColumnName] = useState('id');
  const [sortString, setSortString] = useState('item.id:desc');

  useEffect(() => {
    loadData(currentPage, pageSize, 'id:desc');
  }, []);

  const loadData = (pageNr, pageSize, sortBy) => {
    setError([]);
    setLoading(true);

    getSubCategories(pageNr, pageSize, sortBy)
      .then(response => {
        setTotal(response.data.data.total);

        if (response.data.data.data.length > 0) {
          const flattenedData = response.data.data.data.map(item => ({
            id: item.id,
            name: item.name,
            parent_category: item.parent_category.name,
            added_user: `${item.added_user.first_name} ${item.added_user.last_name}`,
            added_time: moment(item.added_time).format('MM-DD-YYYY'),
            status: item.status.name,
            description: item.description
          }));

          setData(flattenedData);
        }
      })
      .catch(err => {
        try {
          if (err.response.status === 401 || err.response.status === 400) {
            navigate('/sign-in');
          }

          setError(err.response.data.errors);
        } catch (ex) {
          console.log(ex);
          setError(['Please try again later...']);
        }
      });

    setTimeout(function () {
      setLoading(false);
    }, 1000);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const CustomSortCaretUpdatedByOrder = (order, dataField) => {
    if (columnName === 'id')
      return (
        <span className="custom-sort-cart">
          <div style={{ display: 'inline-block' }}>
            ▲
            <br />▼
          </div>
        </span>
      );
    else if (sortColumn === 'asc')
      return (
        <span className="custom-sort-cart">
          <div style={{ display: 'inline-block' }}>
            <div style={{ display: 'inline-block' }}> ▲ </div>
            <br />
            <div style={{ display: 'inline-block', visibility: 'collapse' }}>
              ▼
            </div>
          </div>
        </span>
      );
    else if (sortColumn === 'desc')
      return <span className="custom-sort-cart">▼</span>;
    return null;
  };

  const columns = [
    {
      dataField: 'name',
      text: 'NAME',
      headerStyle: { width: '20%', fontSize: '13px' },
      sort: true,
      sortCaret:
        columnName === 'name' ? CustomSortCaretUpdatedByOrder : CustomSortCaret,
      style: {
        fontSize: '12px',
        fontWeight: 'bold',
        color: 'phoenix-gray-1100'
      }
    },
    // {
    //   dataField: 'parent_category',
    //   text: 'PARENT CATEGORY',
    //   headerStyle: { width: '15%', fontSize: '13px' },
    //   sort: true,
    //   sortCaret:
    //     columnName === 'parent_category'
    //       ? CustomSortCaretUpdatedByOrder
    //       : CustomSortCaret,
    //   style: {
    //     fontSize: '12px',
    //     fontWeight: 'bold',
    //     color: 'phoenix-gray-1100'
    //   },
    //   formatter: (cell, row) => {
    //     return <>{row.parent_category}</>;
    //   }
    // },
    {
      dataField: 'added_user',
      text: 'CREATED BY',
      headerStyle: { width: '20%', fontSize: '13px' },
      sort: true,
      sortCaret:
        columnName === 'added_user'
          ? CustomSortCaretUpdatedByOrder
          : CustomSortCaret,
      style: {
        fontSize: '12px',
        color: 'phoenix-gray-1100'
      },
      formatter: (cell, row) => {
        return <>{row.added_user}</>;
      }
    },
    {
      dataField: 'added_time',
      text: 'CREATED DATE',
      headerStyle: { width: '20%', fontSize: '13px' },
      sort: true,
      sortCaret:
        columnName === 'added_time'
          ? CustomSortCaretUpdatedByOrder
          : CustomSortCaret,
      style: {
        fontSize: '12px',
        color: 'phoenix-gray-1100'
      },
      formatter: (cell, row) => {
        return <>{row.added_time}</>;
      }
    },

    {
      dataField: 'status',
      text: 'STATUS',
      sort: true,
      sortCaret:
        columnName === 'status'
          ? CustomSortCaretUpdatedByOrder
          : CustomSortCaret,
      headerStyle: { width: '15%', fontSize: '13px' },
      style: {
        fontSize: '12px',
        color: 'phoenix-gray-1100'
      },
      formatter: (cell, row) => {
        if (row.status === 'Active') {
          return (
            <Badge
              bg={'success'}
              variant="phoenix"
              iconPosition="end"
              className="fs-10"
              icon={<FeatherIcon icon={'check'} size={12} className="ms-1" />}
            >
              {row.status}
            </Badge>
          );
        } else {
          return (
            <Badge
              bg={'danger'}
              variant="phoenix"
              iconPosition="end"
              className="fs-10"
              icon={<FeatherIcon icon={'info'} size={12} className="ms-1" />}
            >
              {row.status}
            </Badge>
          );
        }
      }
    },

    {
      dataField: 'description',
      text: 'DESCRIPTION',
      sort: true,
      sortCaret:
        columnName === 'description'
          ? CustomSortCaretUpdatedByOrder
          : CustomSortCaret,
      headerStyle: { width: '40%', fontSize: '13px' },
      style: {
        fontSize: '12px',
        color: 'phoenix-gray-1100'
      },
      formatter: (cell, row) => {
        return <>{row.description}</>;
      }
    },

    {
      dataField: 'actions',
      formatter: (cell, row) => (
        <div className="table-action-button">
          <RevealDropdownTrigger>
            <RevealDropdown onSelect={handleSelect}>
              <Dropdown.Item eventKey={`edit:${row.id}`}>Edit</Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item
                key={`${row.id}`}
                className="text-danger"
                eventKey={`delete:${row.id}`}
              >
                Delete
              </Dropdown.Item>
            </RevealDropdown>
          </RevealDropdownTrigger>
        </div>
      )
    }
  ];

  const handleSelect = eventKey => {
    const parts = eventKey.split(':');
    if (parts[0] === 'edit') {
      navigate(`/weapons/sub-category/edit-sub-category/${parts[1]}`);
    }
    if (parts[0] === 'delete') {
      navigate(`/weapons/sub-category/delete-sub-category/${parts[1]}`);
    }
  };

  const handleAllClick = () => {
    setCurrentPage(1);
    loadData(1, 10, 'id:desc');
  };

  const tabItems = [
    {
      label: 'All',
      value: 'all',
      count: total,
      onClick: handleAllClick
    }
  ];

  const table = useAdvanceTable({
    data: data
  });

  // const handleSearchInputChange = e => {};

  const handleTableChange = (
    type,
    { page, sizePerPage, sortField, sortOrder, data }
  ) => {
    switch (type) {
      case 'pagination':
        pagination(page, sizePerPage);
        break;
      case 'sort':
        sort(sortField, sortOrder, data);
        break;
      default:
        break;
    }
  };

  const pagination = (page, sizePerPage) => {
    setCurrentPage(page);
    setPageSize(sizePerPage);
    loadData(page, sizePerPage, sortString);
  };

  const sort = sortField => {
    setCurrentPage(1);
    if (sortColumn === 'desc') {
      setSortColumn('asc');
    } else if (sortColumn === 'asc') {
      setSortColumn('desc');
    }
    switch (sortField) {
      case 'name':
        setColumnName('name');
        setSortString(`name:${sortColumn}`);
        loadData(1, 10, `name:${sortColumn}`);
        break;
      case 'parent_category':
        setColumnName('parent_category');
        setSortString(`item_category.name:${sortColumn}`);
        loadData(1, 10, `item_category.name:${sortColumn}`);
        break;
      case 'added_user':
        setColumnName('added_user');
        setSortString(
          `user_item_category_added_user_idTouser.first_name:${sortColumn}`
        );
        loadData(
          1,
          10,
          `user_item_category_added_user_idTouser.first_name:${sortColumn}`
        );
        break;
      case 'added_time':
        setColumnName('added_time');
        setSortString(`added_time:${sortColumn}`);
        loadData(1, 10, `added_time:${sortColumn}`);
        break;
      case 'status':
        setColumnName('status');
        setSortString(`status.name:${sortColumn}`);
        loadData(1, 10, `status.name:${sortColumn}`);
        break;
      case 'description':
        setColumnName('description');
        setSortString(`description:${sortColumn}`);
        loadData(1, 10, `description:${sortColumn}`);
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <div className="mb-9">
        <h2 className="mb-4">Category</h2>
        <FilterTab tabItems={tabItems} className="mb-2" />

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <div className="d-flex flex-wrap gap-3">
              {/* <SearchBox
                placeholder="Search orders"
                onChange={handleSearchInputChange}
              /> */}
              <div className="scrollbar overflow-hidden-y"></div>
              <div className="ms-xxl-auto">
                <Button
                  variant="primary"
                  onClick={() =>
                    navigate(`/weapons/sub-category/add-sub-category/`)
                  }
                >
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Add Category
                </Button>
              </div>
            </div>
          </div>

          <div>
            {Object.entries(error).length > 0 &&
              error.map((e, index) => (
                <Alert variant="soft-danger" key={index}>
                  {e}
                </Alert>
              ))}
          </div>

          <div className="bg-white border-top border-bottom border-200 position-relative top-1">
            {loading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: '200px' }}
              >
                <Spinner animation="border" role="status"></Spinner>
              </div>
            ) : (
              <PaginationProvider
                pagination={paginationFactory({
                  custom: true,
                  page: currentPage,
                  sizePerPage: pageSize,
                  totalSize: total,
                  sizePerPageList: [
                    {
                      text: '10',
                      value: 10
                    }
                  ]
                })}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <div>
                    <BootstrapTable
                      remote
                      keyField="id"
                      data={data.length === 0 ? [] : data}
                      columns={columns}
                      bordered={false}
                      onTableChange={handleTableChange}
                      {...paginationTableProps}
                    />
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          marginTop: '-10px',
                          fontSize: '13px'
                        }}
                      >
                        <PaginationTotalStandalone {...paginationProps} />
                        <PaginationListStandalone {...paginationProps} />
                      </div>
                    </div>
                  </div>
                )}
              </PaginationProvider>
            )}
          </div>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default SubCategory;
