import axios from 'axios';
import siteConfig from '../config/site.config';
import { getNewToken } from './api';
import { redirectToLogin } from '../helpers/navigation';

// for multiple requests
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

const axiosInstance = axios.create({
  baseURL: siteConfig.apiUrl,
  timeout: 10000 // Set a timeout
});

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  error => {
    const originalReq = error.config;
    // console.log(error.response);
    if (error.response.status === 401 && !originalReq._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then(token => {
            originalReq.headers['authorization'] = token;
            return axios(originalReq);
          })
          .catch(err => {
            return Promise.reject(err);
          });
      }

      originalReq._retry = true;
      isRefreshing = true;

      const refToken = localStorage.getItem('st_john_refresh_token');
      const refreshToken = {
        refresh_token: refToken
      };

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      return new Promise((resolve, reject) => {
        getNewToken(refreshToken)
          .then(response => {
            localStorage.setItem('st_john_token', response.data.data.token);
            localStorage.setItem(
              'st_john_refresh_token',
              response.data.data.refreshToken
            );

            originalReq.headers['authorization'] = response.data.data.token;

            processQueue(null, response.data.data.token);

            resolve(axios(originalReq));
          })
          .catch(err => {
            // processQueue(err, null);
            // return Promise.reject(err);
            if (err.response.status === 401 || err.response.status === 400) {
              redirectToLogin();
            }
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    } else {
      return Promise.reject(error);
    }
  }
);

export default axiosInstance;
