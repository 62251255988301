import { useState, useEffect, useRef } from 'react';

const useInactivityTimer = (timeout = 900000, onTimeout) => {
  const [isInactive, setIsInactive] = useState(false);
  const timeoutRef = useRef(null);

  const resetTimer = () => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      setIsInactive(true);
      if (onTimeout) onTimeout();
    }, timeout);
  };

  useEffect(() => {
    const handleActivity = () => {
      setIsInactive(false);
      resetTimer();
    };

    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);

    resetTimer();

    return () => {
      clearTimeout(timeoutRef.current);
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
    };
  }, [timeout, onTimeout]);

  return isInactive;
};

export default useInactivityTimer;
