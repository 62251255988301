import { useState, ChangeEvent } from 'react';
import Button from 'components/base/Button';
import { useNavigate, useLocation } from 'react-router-dom';
import { resetPassword } from '../../../api/api';
import { Form, Alert } from 'react-bootstrap';

interface FormData {
  email: string;
  password: string;
  confirmPassword: string;
}

const ResetPasswordForm = () => {
  const navigate = useNavigate();
  const [error, setError] = useState<string[]>([]);
  const [formData, setFormData] = useState<FormData>({
    email: '',
    password: '',
    confirmPassword: ''
  });
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError([]);

    if (formData.password !== formData.confirmPassword) {
      setError(['Passwords do not match.']);
      return;
    }

    const data = {
      email: formData.email,
      password: formData.password,
      token: token
    };

    resetPassword(data)
      .then(response => {
        if (response.status === 200) navigate('/sign-in');
      })
      .catch(err => {
        try {
          if (err.response.status === 401 || err.response.status === 400) {
            navigate('/sign-in');
          }
          setError(err.response.data.errors);
        } catch (ex) {
          setError(['Please try again later...']);
        }
      });
  };

  return (
    <div className="text-center mb-6">
      <h4 className="text-1000">Reset new password</h4>
      <p className="text-700">Type your new password</p>

      {error &&
        error.map((e: string, index: number) => (
          <Alert variant="soft-danger" key={index}>
            {e}
          </Alert>
        ))}

      <Form className="mt-5" onSubmit={handleSubmit}>
        <Form.Control
          className="mb-2"
          id="email"
          name="email"
          type="email"
          placeholder="Type your email"
          onChange={handleInputChange}
          required
        />
        <Form.Control
          className="mb-2"
          id="password"
          name="password"
          type="password"
          placeholder="Type new password"
          onChange={handleInputChange}
          required
        />
        <Form.Control
          className="mb-4"
          id="confirmPassword"
          name="confirmPassword"
          type="password"
          placeholder="Confirm new password"
          onChange={handleInputChange}
          required
        />
        <Button variant="primary" type="submit" className="w-100">
          Set Password
        </Button>
      </Form>
    </div>
  );
};

export default ResetPasswordForm;
