import React, { useState, useEffect } from 'react';
import Button from 'components/base/Button';
import { withTheme } from 'react-jsonschema-form';
import LayoutField from 'react-jsonschema-form-layout-2';
import Bootstrap4Theme from 'react-jsonschema-form-bs4';
import { Col, Row, Alert } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingOverlay from 'components/loading-overlay/LoadingOverlay';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { patchCategory, getCategories, getCategory } from 'api/api';

const breadcrumbItems = [
  {
    label: 'Sub Category',
    url: '/weapons/sub-category'
  },
  {
    label: 'Edit',
    active: true
  }
];

function EditSubCategory() {
  const navigate = useNavigate();
  const { id } = useParams();
  const Form = withTheme(Bootstrap4Theme);
  const [formData, setFormData] = useState();
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState(false);
  const [categoryName, setCategoryName] = useState([]);
  const [categoryIds, setCategoryIds] = useState([]);

  useEffect(() => {
    loadCategories();
  }, []);

  useEffect(() => {
    loadData(id);
  }, [id]);

  const loadCategories = () => {
    setError([]);
    setLoading(true);

    getCategories()
      .then(response => {
        const data = response.data.data.data;
        const keys = data.map(g => g.id);
        const names = data.map(g => g.name);
        setCategoryIds(keys);
        setCategoryName(names);
      })
      .catch(err => {
        try {
          setError(err.response.data.errors);
        } catch (ex) {
          setError(['Please try again later...']);
        }
      });

    setTimeout(function () {
      setLoading(false);
    }, 1000);
  };

  const loadData = id => {
    setError([]);
    setLoading(true);

    getCategory(id)
      .then(response => {
        setFormData({
          id: response.data.data.id,
          name: response.data.data.name,
          parent_id: response.data.data.parent_category.id,
          description:
            response.data.data.description === null
              ? ''
              : response.data.data.description
        });
      })
      .catch(err => {
        try {
          setError(err.response.data.errors);
        } catch (ex) {
          setError(['Please try again later...']);
        }
      });

    setTimeout(function () {
      setLoading(false);
    }, 1000);
  };

  const handleSubmit = ({ formData }) => {
    setError([]);
    setFormData(formData);
    setLoading(true);

    patchCategory(formData.id, formData)
      .then(response => {
        if (response.status === 200) navigate('/weapons/sub-category');
      })
      .catch(err => {
        try {
          setError(err.response.data.errors);
        } catch (error) {
          setError(['Please try again later...']);
        }
      });

    setTimeout(function () {
      setLoading(false);
    }, 1000);
  };

  const schema = {
    type: 'object',
    required: ['name'],
    properties: {
      name: {
        title: 'Category Name',
        type: 'string'
      },
      parent_id: {
        title: 'Main Category',
        type: 'number',
        enum: categoryIds,
        enumNames: categoryName,
        readOnly: true
      },
      description: {
        title: 'Description',
        type: 'string'
      }
    }
  };

  const uiSchema = {
    'ui:field': 'layout',
    name: {
      'ui:autofocus': true
    },
    description: {
      'ui:widget': 'textarea'
    },
    'ui:layout': [
      { name: { md: 8 } },
      { parent_id: { md: 8 } },
      { description: { md: 8 } }
    ]
  };

  const fields = {
    layout: LayoutField
  };

  return (
    <div>
      <PageBreadcrumb items={breadcrumbItems} />
      <div className="mb-9">
        <div className="d-flex flex-wrap gap-3 flex-between-end mb-5">
          <div>
            <h2 className="mb-2">Edit Category</h2>
            <h5 className="text-700 fw-semi-bold">Edit a category</h5>
          </div>
        </div>
        <Row className="g-5">
          <Col xs={12} xl={8}>
            <div>
              <div>
                {Object.entries(error).length > 0 &&
                  error.map((e, index) => (
                    <Alert variant="soft-danger" key={index}>
                      {e}
                    </Alert>
                  ))}
              </div>

              {loading && <LoadingOverlay />}

              <Form
                schema={schema}
                uiSchema={uiSchema}
                fields={fields}
                onSubmit={handleSubmit}
                formData={formData}
                showErrorList={false}
              >
                <div
                  className="d-flex flex-wrap gap-2"
                  style={{ marginTop: '10px' }}
                >
                  <Button variant="primary" type="submit">
                    Submit
                  </Button>
                  <Button
                    variant="phoenix-secondary"
                    type="button"
                    onClick={() => navigate('/weapons/sub-category')}
                  >
                    Discard
                  </Button>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default EditSubCategory;
