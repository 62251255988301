import { useState, useEffect, useRef } from 'react';
import Button from 'components/base/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert, Form, Row, Col, InputGroup } from 'react-bootstrap';
import LoadingOverlay from 'components/loading-overlay/LoadingOverlay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { patchUser, getUser, getUserGroups } from 'api/api';

const breadcrumbItems = [
  {
    label: 'Users',
    url: '/users/users'
  },
  {
    label: 'Edit',
    active: true
  }
];

const EditUser = () => {
  const navigate = useNavigate();
  const animatedComponents = makeAnimated();
  const firstNameInputRef = useRef(null);
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [userGroups, setUserGroup] = useState([]);
  const [userGroupId, setUserGroupId] = useState(null);
  const [pin, setPin] = useState('');
  const [confirmPin, setConfirmPin] = useState('');
  const [systemAccess, setSystemAccess] = useState(false);
  const [weaponAccess, setWeaponAccess] = useState(false);
  const [pinMatch, setPinMatch] = useState(true);
  const [showPin, setShowPin] = useState(false);
  const [showConfirmPin, setShowConfirmPin] = useState(false);
  const [idNumber, setIdNumber] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    loadData(id);
  }, [id]);

  useEffect(() => {
    loadUserRoles();
  }, []);

  const loadUserRoles = () => {
    setError([]);
    setLoading(true);

    getUserGroups()
      .then(response => {
        const data = response.data.data;
        setUserGroup(data.data);
      })
      .catch(err => {
        try {
          setError(err.response.data.errors);
        } catch (ex) {
          console.log(ex);
          setError(['Please try again later...']);
        }
      });

    setTimeout(function () {
      setLoading(false);
    }, 1000);
  };

  const loadData = id => {
    setError([]);
    setLoading(true);

    getUser(id)
      .then(response => {
        setFirstName(response.data.data.first_name);
        setLastName(response.data.data.last_name);
        setIdNumber(response.data.data.id_no);
        setSystemAccess(response.data.data.user_group.system_access);
        setWeaponAccess(response.data.data.user_group.weapon_access);
        setEmail(response.data.data.email);
        setUserGroupId({
          value: response.data.data.user_group.id,
          label: response.data.data.user_group.name
        });
      })
      .catch(err => {
        try {
          setError(err.response.data.errors);
        } catch (ex) {
          setError(['Please try again later...']);
        }
      });

    setTimeout(function () {
      setLoading(false);
    }, 1000);
  };

  const handleSubmit = e => {
    e.preventDefault();

    setError([]);
    setPasswordsMatch(true);
    setPinMatch(true);
    setError([]);

    if (password !== confirmPassword) {
      setPasswordsMatch(false);
      return;
    }

    if (pin.length !== 4) {
      setError(['Pin should be a 4 digit number']);
      return;
    }

    if (pin !== confirmPin) {
      setPinMatch(false);
      return;
    }

    setLoading(true);

    const formData = {
      first_name: firstName,
      last_name: lastName,
      user_group_id: userGroupId.value,
      email: email,
      password: password,
      login_pin: pin !== null && parseInt(pin)
    };

    patchUser(id, formData)
      .then(response => {
        if (response.status === 200) navigate('/users/users');
      })
      .catch(err => {
        try {
          setError(err.response.data.errors);
        } catch (error) {
          setError(['Please try again later...']);
        }
      });

    setTimeout(function () {
      setLoading(false);
    }, 1000);
  };

  const handleEmailChange = e => {
    setEmail(e);
  };

  const handlePasswordChange = e => {
    setPassword(e);
  };

  const handleConfirmPasswordChange = e => {
    setConfirmPassword(e);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const togglePasswordConfirmVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleUserGroupChange = e => {
    setSystemAccess(false);
    setWeaponAccess(false);

    const userGroup = userGroups.find(g => g.id === e.value);

    if (userGroup !== undefined && userGroup !== null) {
      if (userGroup.system_access) setSystemAccess(true);

      if (userGroup.weapon_access) setWeaponAccess(true);

      setUserGroupId({
        value: userGroup.id,
        label: userGroup.name
      });
    }
  };

  const handleChangePin = event => {
    const inputValue = event.target.value;
    const regex = /^[0-9]*$/;
    if (regex.test(inputValue)) {
      setPin(inputValue);
    }
  };

  const handleChangeConfirmPin = event => {
    const inputValue = event.target.value;
    const regex = /^[0-9]*$/;
    if (regex.test(inputValue)) {
      setConfirmPin(inputValue);
    }
  };

  return (
    <div>
      <PageBreadcrumb items={breadcrumbItems} />
      <div className="mb-9">
        <div className="d-flex flex-wrap gap-3 flex-between-end mb-5">
          <div>
            <h2 className="mb-2">Edit User</h2>
            <h5 className="text-700 fw-semi-bold">Edit a user</h5>
          </div>
        </div>
        <div>
          {Object.entries(error).length > 0 &&
            error.map((e, index) => (
              <Alert variant="soft-danger" key={index}>
                {e}
              </Alert>
            ))}
        </div>

        {loading && <LoadingOverlay />}

        <div>
          <form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12} xl={4}>
                <label>First Name*</label>
                <Form.Control
                  className="mb-2"
                  type="text"
                  value={firstName}
                  onChange={e => setFirstName(e.target.value)}
                  ref={firstNameInputRef}
                  required
                />
              </Col>

              <Col xs={12} xl={4}>
                <label>Last Name*</label>
                <Form.Control
                  className="mb-2"
                  type="text"
                  value={lastName}
                  onChange={e => setLastName(e.target.value)}
                  required
                />
              </Col>
            </Row>

            <Row>
              <Col xs={12} xl={4}>
                <label>ID number*</label>
                <Form.Control
                  className="mb-2"
                  type="text"
                  value={idNumber}
                  onChange={e => setIdNumber(e.target.value)}
                  required
                />
              </Col>

              <Col xs={12} xl={4}>
                <label>User Group*</label>
                <Select
                  classNamePrefix="react-select"
                  className="mb-2"
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  value={userGroupId}
                  options={
                    userGroups &&
                    userGroups.map(c => ({
                      value: c.id,
                      label: c.name
                    }))
                  }
                  onChange={e => handleUserGroupChange(e)}
                  required
                  name="user_group"
                  menuPlacement="auto"
                />
              </Col>
            </Row>

            {systemAccess && (
              <Row>
                <Col xs={12} xl={4}>
                  <label>Email*</label>
                  <Form.Control
                    className="mb-2"
                    type="email"
                    value={email}
                    onChange={e => handleEmailChange(e.target.value)}
                    //required
                  />
                </Col>
              </Row>
            )}

            {systemAccess && (
              <Row>
                <Col xs={12} xl={4}>
                  <label>Password</label>
                  <InputGroup>
                    <Form.Control
                      className="mb-2"
                      type={showPassword ? 'text' : 'password'}
                      value={password}
                      onChange={e => handlePasswordChange(e.target.value)}
                      // required
                    />
                    <InputGroup.Text
                      onClick={togglePasswordVisibility}
                      style={{ height: '38px' }}
                    >
                      <FontAwesomeIcon
                        icon={showPassword ? faEyeSlash : faEye}
                      />
                    </InputGroup.Text>
                  </InputGroup>
                </Col>

                <Col xs={12} xl={4}>
                  <label>Confirm Password</label>
                  <InputGroup>
                    <Form.Control
                      className="mb-2"
                      type={showConfirmPassword ? 'text' : 'password'}
                      value={confirmPassword}
                      onChange={e =>
                        handleConfirmPasswordChange(e.target.value)
                      }
                      // required
                    />
                    <InputGroup.Text
                      onClick={togglePasswordConfirmVisibility}
                      style={{ height: '38px' }}
                    >
                      <FontAwesomeIcon
                        icon={showConfirmPassword ? faEyeSlash : faEye}
                      />
                    </InputGroup.Text>{' '}
                  </InputGroup>
                  {!passwordsMatch && (
                    <p className="mb-2" style={{ color: 'red' }}>
                      Passwords do not match.
                    </p>
                  )}
                </Col>
              </Row>
            )}

            {weaponAccess && (
              <Row>
                <Col xs={12} xl={4}>
                  <label>Pin</label>
                  <InputGroup>
                    <Form.Control
                      className="mb-2"
                      type={showPin ? 'text' : 'password'}
                      value={pin}
                      placeholder="Enter a 4-digit number"
                      onChange={e => handleChangePin(e)}
                      maxLength={4}
                      //  required
                    />
                    <InputGroup.Text
                      onClick={() => setShowPin(!showPin)}
                      style={{ height: '38px' }}
                    >
                      <FontAwesomeIcon icon={showPin ? faEyeSlash : faEye} />
                    </InputGroup.Text>
                  </InputGroup>
                </Col>

                <Col xs={12} xl={4}>
                  <label>Confirm Pin</label>
                  <InputGroup>
                    <Form.Control
                      className="mb-2"
                      type={showConfirmPin ? 'text' : 'password'}
                      value={confirmPin}
                      onChange={e => handleChangeConfirmPin(e)}
                      maxLength={4}
                      //required
                    />
                    <InputGroup.Text
                      onClick={() => setShowConfirmPin(!showConfirmPin)}
                      style={{ height: '38px' }}
                    >
                      <FontAwesomeIcon
                        icon={showConfirmPin ? faEyeSlash : faEye}
                      />
                    </InputGroup.Text>{' '}
                  </InputGroup>
                  {!pinMatch && (
                    <p className="mb-2" style={{ color: 'red' }}>
                      Pin do not match.
                    </p>
                  )}
                </Col>
              </Row>
            )}

            <Row>
              <Col xs={12} xl={4}>
                <Button variant="primary" type="submit" className="mb-2">
                  Submit
                </Button>
                <Button
                  className="mb-2"
                  variant="phoenix-secondary"
                  type="button"
                  onClick={() => navigate('/users/users')}
                >
                  Discard
                </Button>
              </Col>
            </Row>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditUser;
