import { useState, useRef, useEffect } from 'react';
import Button from 'components/base/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert, Form, Row, Col } from 'react-bootstrap';
import LoadingOverlay from 'components/loading-overlay/LoadingOverlay';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import Autosuggest from 'react-autosuggest';
import {
  getItemUnits,
  patchItem,
  getSubCategories,
  getLocations,
  getContainerByLocationId,
  getItem,
  searchItemMetadata
} from 'api/api';

const breadcrumbItems = [
  {
    label: 'Weapons',
    url: '/weapons/weapons'
  },
  {
    label: 'Edit',
    active: true
  }
];

const EditWeapon = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const nameInputRef = useRef(null);
  const animatedComponents = makeAnimated();
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState(false);
  const [subCategoryName, setSubCategoryName] = useState([]);
  const [containerName, setContainerName] = useState([]);
  const [unitNames, setUnitNames] = useState([]);
  const [locationName, setLocationName] = useState([]);
  const [name, setName] = useState('');
  const [sku, setSku] = useState('');
  const [category_id, setCategory_id] = useState(null);
  const [location_id, setLocation_id] = useState(null);
  const [container_id, setContainer_id] = useState(null);
  const [item_unit_id, setItem_unit_id] = useState(null);
  const [description, setDescription] = useState('');
  const [suggestionsListMake, setSuggestionsListMake] = useState([]);
  const [suggestionsListCalibre, setSuggestionsListCalibre] = useState([]);

  useEffect(() => {
    if (nameInputRef.current) {
      nameInputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    loadData(id);
  }, []);

  const loadData = async id => {
    setError([]);
    setLoading(true);

    await loadLocations();
    await loadItemUnits();
    await loadSubCategories();

    getItem(id)
      .then(async response => {
        const data = response.data.data;

        await loadContainers(data.container.location.id);

        setName(data.name);
        setSku(data.sku);

        setLocation_id({
          value: data.container.location.id,
          label: data.container.location.name
        });

        setContainer_id({
          value: data.container.id,
          label: data.container.name
        });

        setDescription(data.description === null ? '' : data.description);
        setItem_unit_id({
          value: data.item_unit.id,
          label: data.item_unit.unit
        });

        setCategory_id({ value: data.category.id, label: data.category.name });
      })
      .catch(err => {
        try {
          setError(err.response.data.errors);
        } catch (ex) {
          setError(['Please try again later...']);
        }
      });

    setTimeout(function () {
      setLoading(false);
    }, 1000);
  };

  const loadSubCategories = async () => {
    setError([]);
    setSubCategoryName([]);
    setCategory_id(null);

    getSubCategories(1, 0, 'name:asc')
      .then(response => {
        const data = response.data.data.data;
        setSubCategoryName(data);
      })
      .catch(err => {
        try {
          setError(err.response.data.errors);
        } catch (ex) {
          setError(['Please try again later...']);
        }
      });
  };

  const loadLocations = async () => {
    setError([]);
    setLocationName([]);

    getLocations()
      .then(response => {
        const data = response.data.data.data;
        setLocationName(data);
      })
      .catch(err => {
        try {
          setError(err.response.data.errors);
        } catch (ex) {
          setError(['Please try again later...']);
        }
      });
  };

  const loadContainers = async id => {
    setError([]);
    setLoading(true);
    setContainer_id(null);

    await getContainerByLocationId(id)
      .then(response => {
        const data = response.data.data;
        setContainerName(data);
      })
      .catch(err => {
        try {
          setError(err.response.data.errors);
        } catch (ex) {
          setError(['Please try again later...']);
        }
      });

    setTimeout(function () {
      setLoading(false);
    }, 1000);
  };

  const loadItemUnits = async () => {
    setError([]);
    setLoading(true);

    await getItemUnits()
      .then(response => {
        const data = response.data.data;
        setUnitNames(data);
      })
      .catch(err => {
        try {
          setError(err.response.data.errors);
        } catch (ex) {
          setError(['Please try again later...']);
        }
      });

    setTimeout(function () {
      setLoading(false);
    }, 1000);
  };

  const handleSubmit = e => {
    e.preventDefault();

    setError([]);
    setLoading(true);

    const formData = {
      name: name,
      sku: sku,
      category_id: parseInt(category_id.value),
      container_id: parseInt(container_id.value),
      item_unit_id: parseInt(item_unit_id.value),
      description: description
    };

    patchItem(id, formData)
      .then(response => {
        if (response.status === 200) navigate('/weapons/weapons');
      })
      .catch(err => {
        try {
          setError(err.response.data.errors);
        } catch (error) {
          setError(['Please try again later...']);
        }
      });

    setTimeout(function () {
      setLoading(false);
    }, 1000);
  };

  const handleChangeLocation = e => {
    loadContainers(e.value);
    setLocation_id(e);
  };

  const customStyles = {
    singleValue: provided => ({
      ...provided,
      fontSize: '12px'
    })
  };

  // Autosuggest functions Make
  const onSuggestionsFetchRequestedMake = ({ value }) => {
    searchItemMetadata('make', value.trim())
      .then(response => {
        if (response.data.data.make !== undefined)
          setSuggestionsListMake(response.data.data.make);
        else setSuggestionsListMake([]);
      })
      .catch(err => {
        try {
          setError(err.response.data.errors);
          setSuggestionsListMake([]);
        } catch (error) {
          setError(['Please try again later...']);
          setSuggestionsListMake([]);
        }
      });
  };

  const onSuggestionsClearRequestedMake = () => {
    setSuggestionsListMake([]);
  };

  const onChangeMake = (event, { newValue }) => {
    setName(newValue);
  };

  const getSuggestionValueMake = suggestion => suggestion;

  const renderSuggestionMake = suggestion => <div>{suggestion}</div>;

  const inputPropsMake = {
    placeholder: 'Type a Make',
    value: name,
    className: 'form-control',
    onChange: onChangeMake,
    required: true
  };

  // Autosuggest functions Calibre
  const onSuggestionsFetchRequestCalibre = ({ value }) => {
    searchItemMetadata('calibre', value.trim())
      .then(response => {
        if (response.data.data.calibre !== undefined)
          setSuggestionsListCalibre(response.data.data.calibre);
        else setSuggestionsListCalibre([]);
      })
      .catch(err => {
        try {
          setError(err.response.data.errors);
          setSuggestionsListCalibre([]);
        } catch (error) {
          setError(['Please try again later...']);
          setSuggestionsListCalibre([]);
        }
      });
  };

  const onSuggestionsClearRequestedCalibre = () => {
    setSuggestionsListCalibre([]);
  };

  const onChangeCalibre = (event, { newValue }) => {
    setSku(newValue);
  };

  const getSuggestionValueCalibre = suggestion => suggestion;

  const renderSuggestionCalibre = suggestion => <div>{suggestion}</div>;

  // Autosuggest input props
  const inputPropsCalibre = {
    placeholder: 'Type a Calibre',
    value: sku,
    className: 'form-control',
    onChange: onChangeCalibre,
    required: true
  };

  return (
    <div>
      <PageBreadcrumb items={breadcrumbItems} />
      <div className="mb-9">
        <div className="d-flex flex-wrap gap-3 flex-between-end mb-5">
          <div>
            <h2 className="mb-2">Edit Weapon</h2>
            <h5 className="text-700 fw-semi-bold">Edit a weapon</h5>
          </div>
        </div>
        <div>
          {Object.entries(error).length > 0 &&
            error.map((e, index) => (
              <Alert variant="soft-danger" key={index}>
                {e}
              </Alert>
            ))}
        </div>

        {loading && <LoadingOverlay />}

        <div>
          <form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12} xl={4}>
                <label>Make*</label>
                <Autosuggest
                  suggestions={suggestionsListMake}
                  onSuggestionsFetchRequested={onSuggestionsFetchRequestedMake}
                  onSuggestionsClearRequested={onSuggestionsClearRequestedMake}
                  getSuggestionValue={getSuggestionValueMake}
                  renderSuggestion={renderSuggestionMake}
                  inputProps={inputPropsMake}
                />
              </Col>

              <Col xs={12} xl={4}>
                <label>Model*</label>
                <Form.Control
                  className="mb-2"
                  type="text"
                  value={description}
                  onChange={e => setDescription(e.target.value)}
                  required
                />
              </Col>
            </Row>

            <Row>
              <Col xs={12} xl={4}>
                <label>Category*</label>
                <Select
                  classNamePrefix="react-select"
                  className="mb-2"
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  options={
                    subCategoryName &&
                    subCategoryName.map(c => ({
                      value: c.id,
                      label: c.name
                    }))
                  }
                  onChange={e => setCategory_id(e)}
                  value={category_id}
                  required
                  name="sub_category"
                  menuPlacement="auto"
                  styles={customStyles}
                />
              </Col>

              <Col xs={12} xl={4}>
                <label>Calibre*</label>
                <Autosuggest
                  suggestions={suggestionsListCalibre}
                  onSuggestionsFetchRequested={onSuggestionsFetchRequestCalibre}
                  onSuggestionsClearRequested={
                    onSuggestionsClearRequestedCalibre
                  }
                  getSuggestionValue={getSuggestionValueCalibre}
                  renderSuggestion={renderSuggestionCalibre}
                  inputProps={inputPropsCalibre}
                />
              </Col>
            </Row>

            <Row>
              <Col xs={12} xl={4}>
                <label>Location*</label>
                <Select
                  classNamePrefix="react-select"
                  className="mb-2"
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  options={
                    locationName &&
                    locationName.map(c => ({
                      value: c.id,
                      label: c.name
                    }))
                  }
                  onChange={e => handleChangeLocation(e)}
                  value={location_id}
                  required
                  name="location"
                  menuPlacement="auto"
                  styles={customStyles}
                />
              </Col>

              <Col xs={12} xl={4}>
                <label>Container*</label>
                <Select
                  classNamePrefix="react-select"
                  className="mb-2"
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  options={
                    containerName &&
                    containerName.map(c => ({
                      value: c.id,
                      label: c.name
                    }))
                  }
                  value={container_id}
                  onChange={e => setContainer_id(e)}
                  required
                  name="container"
                  menuPlacement="auto"
                  styles={customStyles}
                />
              </Col>
            </Row>

            <Row>
              <Col xs={12} xl={4}>
                <label>Unit*</label>
                <Select
                  classNamePrefix="react-select"
                  className="mb-2"
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  options={
                    unitNames &&
                    unitNames.map(c => ({
                      value: c.id,
                      label: c.unit
                    }))
                  }
                  onChange={e => setItem_unit_id(e)}
                  value={item_unit_id}
                  required
                  name="unit"
                  menuPlacement="auto"
                  styles={customStyles}
                />
              </Col>
            </Row>

            <Row>
              <Col xs={12} xl={4}>
                <Button className="mb-2" variant="primary" type="submit">
                  Submit
                </Button>{' '}
                <Button
                  className="mb-2"
                  variant="phoenix-secondary"
                  type="button"
                  onClick={() => navigate('/weapons/weapons')}
                >
                  Discard
                </Button>
              </Col>
            </Row>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditWeapon;
