import AdvanceTable from 'components/base/AdvanceTable';
import Badge from 'components/base/Badge';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import FeatherIcon from 'feather-icons-react';
import { ColumnDef } from '@tanstack/react-table';

import moment from 'moment';

const columns: ColumnDef<LatestOrders>[] = [
  {
    id: 'itemImage',
    accessorKey: '',
    cell: ({ row: { original } }) => {
      const { id, image } = original;
      return (
        <Link
          to={`weapons/weapon-details/${id}`}
          className="rounded-2 border d-inline-block"
          style={{ textAlign: 'center' }}
        >
          <img src={image} alt="" width={53} />
        </Link>
      );
    },
    meta: { cellProps: { className: 'py-0' } },
    enableSorting: false
  },
  {
    accessorKey: 'name',
    header: () => 'Make',
    cell: ({ row: { original } }) => {
      const { id, name } = original;
      return (
        <Link
          to={`/weapons/weapon-details/${id}`}
          className="fw-semi-bold"
        >{`${name.slice(0, 46)}${name.length > 46 ? '...' : ''}`}</Link>
      );
    },
    enableSorting: true,
    meta: {
      headerProps: { style: { minWidth: 150 }, className: 'py-2' }
    }
  },

  {
    accessorKey: 'description',
    header: 'Model',
    cell: ({ row: { original } }) => {
      const { description } = original;
      return <> {description}</>;
    },
    meta: {
      headerProps: { style: { minWidth: 100 } }
    }
  },

  {
    accessorKey: 'serial_number',
    header: 'SERIAL NO',
    cell: ({ row: { original } }) => {
      const { stock } = original;
      return <> {stock.serial_number}</>;
    },
    meta: {
      headerProps: { style: { minWidth: 150 } }
    }
  },

  {
    accessorKey: 'sku',
    header: 'CALIBRE',
    cell: ({ row: { original } }) => {
      const { sku } = original;
      return <> {sku}</>;
    },
    meta: {
      headerProps: { style: { minWidth: 100 } }
    }
  },

  // {
  //   accessorKey: 'location',
  //   header: 'LOCATION',
  //   cell: ({ row: { original } }) => {
  //     const { item } = original;
  //     return <> {item.container.location.name}</>;
  //   },
  //   meta: {
  //     headerProps: { style: { minWidth: 150 } }
  //   }
  // },

  {
    accessorKey: 'added_user',
    header: 'CHECK-OUT BY',
    cell: ({ row: { original } }) => {
      const { added_user } = original;
      return <> {`${added_user.first_name} ${added_user.last_name}`}</>;
    },
    meta: {
      headerProps: { style: { minWidth: 150 } }
    }
  },

  {
    accessorKey: 'added_time',
    header: 'DATE',
    cell: ({ row: { original } }) => {
      const { added_time } = original;
      return <> {`${moment(added_time).format('MM-DD-YYYY HH:mm')}`}</>;
    },
    meta: {
      headerProps: { style: { minWidth: 150 } }
    }
  },

  {
    accessorKey: 'status',
    header: 'STATUS',
    cell: ({ row: { original } }) => {
      const { status } = original;
      return status.name.toLowerCase() === 'in stock' ? (
        <Badge
          bg={'success'}
          variant="phoenix"
          iconPosition="end"
          className="fs-10"
          icon={<FeatherIcon icon={'check'} size={12} className="ms-1" />}
        >
          {status.name}
        </Badge>
      ) : (
        <Badge
          bg={'danger'}
          variant="phoenix"
          iconPosition="end"
          className="fs-10"
          icon={<FeatherIcon icon={'info'} size={12} className="ms-1" />}
        >
          {status.name}
        </Badge>
      );
    },
    meta: {
      headerProps: { className: 'ps-5' },
      cellProps: { className: 'ps-5' }
    }
  }
];

interface Status {
  name: string;
}

interface AddedUser {
  first_name: string;
  last_name: string;
}

interface ItemStock {
  serial_number: string;
}

interface LatestOrders {
  id: number;
  image: string;
  name: string;
  sku: string;
  description: string;
  added_user: AddedUser;
  added_time: string;
  status: Status;
  stock: ItemStock;
}

interface InventoryLatestOrdersTableProps {
  latestOrders: LatestOrders[];
}

const InventoryLatestOrdersTable = ({
  latestOrders
}: InventoryLatestOrdersTableProps) => {
  const table = useAdvanceTable({
    data: latestOrders && latestOrders,
    columns,
    pageSize: 6,
    pagination: true,
    selection: false,
    selectionColumnWidth: '30px',
    sortable: true
  });

  return (
    <>
      <AdvanceTableProvider {...table}>
        <Row className="align-items-end justify-content-between pb-5 g-3">
          <Col xs="auto">
            <h3>Latest checked out</h3>
            <p className="text-700 lh-sm mb-0">
              The most recent checked out that have been completed are as
              follows.
            </p>
          </Col>
        </Row>

        <AdvanceTable
          tableProps={{
            className: 'phoenix-table fs-9 mb-0 border-top border-200'
          }}
          rowClassName="hover-actions-trigger btn-reveal-trigger position-static"
        />
        <AdvanceTableFooter navBtn />
      </AdvanceTableProvider>
    </>
  );
};

export default InventoryLatestOrdersTable;
