import { useEffect, useState } from 'react';
//import SearchBox from 'components/common/SearchBox';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { useNavigate, Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationTotalStandalone,
  PaginationProvider,
  PaginationListStandalone
} from 'react-bootstrap-table2-paginator';
import Button from 'components/base/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Alert, Spinner } from 'react-bootstrap';
import FilterTab from 'components/common/FilterTab';
import moment from 'moment';
import useAdvanceTable from 'hooks/useAdvanceTable';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { CustomSortCaret } from 'helpers/ui-utils';
import { getInventoryLogs } from 'api/api';

const InventoryLogs = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [error, setError] = useState([]);
  const [total, setTotal] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [sortColumn, setSortColumn] = useState('asc');
  const [allCount, setAllCount] = useState(null);
  const [columnName, setColumnName] = useState('id');
  const [clearButtonVisibility, setClearButtonVisibility] = useState(false);
  const [sortString, setSortString] = useState('id:desc');

  useEffect(() => {
    loadData(currentPage, pageSize, undefined, undefined, 'id:desc');
  }, []);

  const loadData = (pageNr, pageSize, startDate, endDate, sortBy) => {
    setError([]);
    setLoading(true);
    setData([]);

    startDate = startDate === 'Invalid date' ? undefined : startDate;
    endDate = endDate === 'Invalid date' ? undefined : endDate;

    getInventoryLogs(pageNr, pageSize, startDate, endDate, sortBy)
      .then(response => {
        setData(response.data.data.data);
        setTotal(response.data.data.total);

        if (response.data.data.data.length > 0) {
          const flattenedData = response.data.data.data.map(item => ({
            id: item.id,
            found_total: item.found_total,
            missing_total: item.missing_total,
            missing_in_active: item.missing_in_active,
            added_user: `${item.added_user.first_name} ${item.added_user.last_name}`,
            added_time:
              item.added_time === null
                ? ''
                : moment(item.added_time).format('MM-DD-YYYY HH:mm')
          }));

          setData(flattenedData);
        }

        setAllCount(response.data.data.total);
      })
      .catch(err => {
        console.log(err.response);
        try {
          if (err.response.status === 401 || err.response.status === 400) {
            navigate('/sign-in');
          }
          setError(err.response.data.errors);
        } catch (ex) {
          console.log(ex);
          setError(['Please try again later...']);
        }
      });

    setTimeout(function () {
      setLoading(false);
    }, 1000);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const CustomSortCaretUpdatedByOrder = (order, dataField) => {
    if (sortColumn === 'asc')
      return (
        <span className="custom-sort-cart">
          <div style={{ display: 'inline-block' }}>
            <div style={{ display: 'inline-block' }}> ▲ </div>
            <br />
            <div style={{ display: 'inline-block', visibility: 'collapse' }}>
              ▼
            </div>
          </div>
        </span>
      );
    else if (sortColumn === 'desc')
      return <span className="custom-sort-cart">▼</span>;
    return null;
  };

  const columns = [
    {
      dataField: 'id',
      text: 'ID',
      headerStyle: { width: '15%', fontSize: '13px' },
      sort: true,
      sortCaret:
        columnName === 'id' ? CustomSortCaretUpdatedByOrder : CustomSortCaret,
      style: {
        fontSize: '12px',
        fontWeight: 'bold',
        color: 'phoenix-gray-1100'
      },
      formatter: (cell, row) => {
        return (
          <Link
            to="#!"
            className="fw-semi-bold"
            onClick={() => navigate('/inventory/inventory-details/' + row.id)}
          >
            #{row.id}
          </Link>
        );
      }
    },
    {
      dataField: 'found_total',
      text: 'TOTAL FOUND',
      headerStyle: { width: '15%', fontSize: '13px' },
      sort: true,
      sortCaret:
        columnName === 'found_total'
          ? CustomSortCaretUpdatedByOrder
          : CustomSortCaret,
      style: {
        fontSize: '12px',
        fontWeight: 'bold',
        color: 'phoenix-gray-1100'
      }
    },
    {
      dataField: 'missing_total',
      text: 'TOTAL MISSING',
      headerStyle: { width: '15%', fontSize: '13px' },
      sort: true,
      sortCaret:
        columnName === 'missing_total'
          ? CustomSortCaretUpdatedByOrder
          : CustomSortCaret,
      style: {
        fontSize: '12px',
        fontWeight: 'bold',
        color: 'phoenix-gray-1100'
      }
    },
    {
      dataField: 'missing_in_active',
      text: 'MISSING IN LAST INVENTORY',
      headerStyle: { width: '20%', fontSize: '13px' },
      sort: true,
      sortCaret:
        columnName === 'missing_in_active'
          ? CustomSortCaretUpdatedByOrder
          : CustomSortCaret,
      style: {
        fontSize: '12px',
        fontWeight: 'bold',
        color: 'phoenix-gray-1100'
      }
    },
    {
      dataField: 'added_user',
      text: 'CREATED BY',
      headerStyle: { width: '20%', fontSize: '13px' },
      sort: true,
      sortCaret:
        columnName === 'added_user'
          ? CustomSortCaretUpdatedByOrder
          : CustomSortCaret,
      style: {
        fontSize: '12px',
        color: 'phoenix-gray-1100'
      },
      formatter: (cell, row) => {
        return <>{row.added_user}</>;
      }
    },
    {
      dataField: 'added_time',
      text: 'TIME',
      headerStyle: { width: '20%', fontSize: '13px' },
      sort: true,
      sortCaret:
        columnName === 'added_time'
          ? CustomSortCaretUpdatedByOrder
          : CustomSortCaret,
      style: {
        fontSize: '12px',
        fontWeight: 'bold',
        color: 'phoenix-gray-1100'
      },
      formatter: (cell, row) => {
        return <>{row.added_time}</>;
      }
    }
  ];

  const handleAllClick = () => {
    setCurrentPage(1);
    const start = moment(startDate).format('YYYY-MM-DD');
    const end = moment(endDate).format('YYYY-MM-DD');
    loadData(currentPage, pageSize, start, end, 'id:desc');
  };

  const tabItems = [
    {
      label: 'All Weapons',
      value: 'all',
      count: allCount,
      onClick: handleAllClick
    }
  ];

  const table = useAdvanceTable({
    data: data
  });

  // const handleSearchInputChange = e => {};

  const handleLoadData = () => {
    setCurrentPage(1);
    setClearButtonVisibility(true);
    loadData(
      currentPage,
      pageSize,
      moment(startDate).format('YYYY-MM-DD'),
      moment(endDate).format('YYYY-MM-DD'),
      'id:desc'
    );
  };

  const handleTableChange = (
    type,
    { page, sizePerPage, sortField, sortOrder, data }
  ) => {
    switch (type) {
      case 'pagination':
        pagination(page, sizePerPage);
        break;
      case 'sort':
        sort(sortField, sortOrder, data);
        break;
      default:
        break;
    }
  };

  const pagination = (page, sizePerPage) => {
    setCurrentPage(page);
    setPageSize(sizePerPage);
    const start = moment(startDate).format('YYYY-MM-DD');
    const end = moment(endDate).format('YYYY-MM-DD');
    loadData(page, sizePerPage, start, end, sortString);
  };

  const sort = sortField => {
    setCurrentPage(1);
    if (sortColumn === 'desc') {
      setSortColumn('asc');
    } else if (sortColumn === 'asc') {
      setSortColumn('desc');
    }

    const start = moment(startDate).format('YYYY-MM-DD');
    const end = moment(endDate).format('YYYY-MM-DD');

    switch (sortField) {
      case 'id':
        setColumnName('id');
        setSortString(`id:${sortColumn}`);
        loadData(1, 10, start, end, `id:${sortColumn}`);
        break;
      case 'found_total':
        setColumnName('found_total');
        setSortString(`found_total:${sortColumn}`);
        loadData(1, 10, start, end, `found_total:${sortColumn}`);
        break;
      case 'missing_total':
        setColumnName('missing_total');
        setSortString(`missing_total:${sortColumn}`);
        loadData(1, 10, start, end, `missing_total:${sortColumn}`);
        break;
      case 'missing_in_active':
        setColumnName('missing_in_active');
        setSortString(`missing_in_active:${sortColumn}`);
        loadData(1, 10, start, end, `missing_in_active:${sortColumn}`);
        break;
      case 'added_user':
        setColumnName('added_user');
        setSortString(`user.first_name:${sortColumn}`);
        loadData(1, 10, start, end, `user.first_name:${sortColumn}`);
        break;
      case 'added_time':
        setColumnName('added_time');
        setSortString(`added_time:${sortColumn}`);
        loadData(1, 10, start, end, `added_time:${sortColumn}`);
        break;

      default:
        break;
    }
  };

  const handleClearFilters = () => {
    setStartDate(null);
    setEndDate(null);
    setClearButtonVisibility(false);
    loadData(currentPage, pageSize, undefined, undefined, 'id:desc');
  };

  return (
    <div>
      <div className="mb-9">
        <div>
          <h2 className="mb-2">Inventory Logs</h2>
          <h5 className="text-700 fw-semi-bold">
            {/* {`The latest inventory was conducted by ${name} on ${date}`} */}
          </h5>
        </div>

        <FilterTab tabItems={tabItems} className="mb-2" />

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <div className="d-flex flex-wrap gap-3">
              {/* <SearchBox
                placeholder="Search orders"
                onChange={handleSearchInputChange}
              /> */}
              <div className="scrollbar overflow-hidden-y">
                <DatePicker
                  selected={startDate}
                  onChange={date => setStartDate(date)}
                  placeholder="Start Date"
                  className="form-control"
                  placeholderText="Select start date"
                  dateFormat="MM-dd-yyyy"
                />
              </div>
              <div className="scrollbar overflow-hidden-y">
                <DatePicker
                  selected={endDate}
                  onChange={date => setEndDate(date)}
                  className="form-control"
                  placeholderText="Select end date"
                  dateFormat="MM-dd-yyyy"
                />
              </div>
              <div className="scrollbar overflow-hidden-y">
                <Button
                  variant="primary"
                  type="button"
                  onClick={() => handleLoadData()}
                >
                  Load Inventory
                </Button>
              </div>

              <div className="scrollbar overflow-hidden-y">
                <Button
                  variant="phoenix-secondary"
                  style={{
                    visibility:
                      clearButtonVisibility === true ? 'visible' : 'hidden'
                  }}
                  onClick={() => handleClearFilters()}
                >
                  Clear Filters
                </Button>
              </div>
            </div>
          </div>

          <div>
            {Object.entries(error).length > 0 &&
              error.map((e, index) => (
                <Alert variant="soft-danger" key={index}>
                  {e}
                </Alert>
              ))}
          </div>

          <div className="bg-white border-top border-bottom border-200 position-relative top-1">
            {loading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: '200px' }}
              >
                <Spinner animation="border" role="status"></Spinner>
              </div>
            ) : (
              <PaginationProvider
                pagination={paginationFactory({
                  custom: true,
                  page: currentPage,
                  sizePerPage: pageSize,
                  totalSize: total,
                  sizePerPageList: [
                    {
                      text: '10',
                      value: 10
                    }
                  ]
                })}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <div>
                    <BootstrapTable
                      remote
                      keyField="id"
                      data={data.length === 0 ? [] : data}
                      columns={columns}
                      bordered={false}
                      onTableChange={handleTableChange}
                      {...paginationTableProps}
                    />
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          marginTop: '-10px',
                          fontSize: '13px'
                        }}
                      >
                        <PaginationTotalStandalone {...paginationProps} />
                        <PaginationListStandalone {...paginationProps} />
                      </div>
                    </div>
                  </div>
                )}
              </PaginationProvider>
            )}
          </div>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default InventoryLogs;
