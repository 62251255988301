import { useEffect, useState } from 'react';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationTotalStandalone,
  PaginationProvider,
  PaginationListStandalone
} from 'react-bootstrap-table2-paginator';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Alert, Spinner } from 'react-bootstrap';
import Badge from 'components/base/Badge';
import FilterTab from 'components/common/FilterTab';
import useAdvanceTable from 'hooks/useAdvanceTable';
import FeatherIcon from 'feather-icons-react';
import { CustomSortCaret } from 'helpers/ui-utils';
import { getStockLogs } from 'api/api';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import moment from 'moment';

const StockLogs = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [data, setData] = useState([]);
  const [error, setError] = useState([]);
  const [total, setTotal] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [sortColumn, setSortColumn] = useState('asc');
  const [columnName, setColumnName] = useState('id');
  const [sortString, setSortString] = useState('id:desc');
  const { id, stockId } = useParams();
  const customState = state && state.customState;

  let breadcrumbItems = [];
  if (customState === 'stockItem') {
    breadcrumbItems = [
      {
        label: 'Weapon',
        url: `/weapons/weapons`
      },
      {
        label: 'Weapon Stocks',
        url: `/weapons/stock-weapon/${id}`
      },
      {
        label: 'Logs',
        active: true
      }
    ];
  } else if (customState === 'itemDetails') {
    breadcrumbItems = [
      {
        label: 'Weapon Details',
        url: `/weapons/weapon-details/${id}`
      },
      {
        label: 'Logs',
        active: true
      }
    ];
  } else if (customState === 'weaponAccessLogs') {
    breadcrumbItems = [
      {
        label: 'Weapon Access Logs',
        url: `/reports/weapon-access-logs`
      },
      {
        label: 'Logs',
        active: true
      }
    ];
  } else if (customState === 'inventory') {
    breadcrumbItems = [
      {
        label: 'Inventory',
        url: `/inventory/inventory`
      },
      {
        label: 'Logs',
        active: true
      }
    ];
  }

  useEffect(() => {
    loadData(currentPage, pageSize, sortString);
  }, []);

  const loadData = (pageNr, pageSize, sortBy) => {
    setError([]);
    setLoading(true);
    setData([]);

    getStockLogs(pageNr, pageSize, sortBy, id, stockId)
      .then(response => {
        setTotal(response.data.data.total);
        if (response.data.data.data.length > 0) {
          console.log(response.data.data.data);

          const flattenedData = response.data.data.data.map(item => ({
            id: item.id,
            name: item.item.name,
            item_id: item.item.id,
            sku: item.item.sku,
            description: item.item.description,
            serial_number: item.item.stock.serial_number,
            category: item.item.category.name,
            parent_category: item.item.category.parent_category.name,
            epc: item.item.stock && item.item.stock.epc.slice(-8),
            location: item.item.container.location.name,
            container: item.item.container.name,
            status: item.status.name,
            image: item.item.image,
            stock_id: item.item.stock.id,
            added_time:
              item.added_time === null
                ? ''
                : moment(item.added_time).format('MM-DD-YYYY HH:mm'),
            user:
              item.user === null
                ? ''
                : `${item.user.first_name} ${item.user.last_name}`
          }));

          setData(flattenedData);
        }
      })
      .catch(err => {
        try {
          if (err.response.status === 401 || err.response.status === 400) {
            navigate('/sign-in');
          }
          setError(err.response.data.errors);
        } catch (ex) {
          setError(['Please try again later...']);
        }
      });

    setTimeout(function () {
      setLoading(false);
    }, 1000);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const CustomSortCaretUpdatedByOrder = (order, dataField) => {
    if (columnName === 'id')
      return (
        <span className="custom-sort-cart">
          <div style={{ display: 'inline-block' }}>
            ▲
            <br />▼
          </div>
        </span>
      );
    else if (sortColumn === 'asc')
      return (
        <span className="custom-sort-cart">
          <div style={{ display: 'inline-block' }}>
            <div style={{ display: 'inline-block' }}> ▲ </div>
            <br />
            <div style={{ display: 'inline-block', visibility: 'collapse' }}>
              ▼
            </div>
          </div>
        </span>
      );
    else if (sortColumn === 'desc')
      return <span className="custom-sort-cart">▼</span>;
    return null;
  };

  const columns = [
    {
      dataField: 'added_time',
      text: 'TIME',
      headerStyle: { width: '25%', fontSize: '13px' },
      sort: true,
      sortCaret:
        columnName === 'added_time'
          ? CustomSortCaretUpdatedByOrder
          : CustomSortCaret,
      style: {
        fontSize: '12px',
        fontWeight: 'bold',
        color: 'phoenix-gray-1100'
      },
      formatter: (cell, row) => {
        return <>{row.added_time}</>;
      }
    },

    {
      dataField: 'user',
      text: 'USER',
      headerStyle: { width: '25%', fontSize: '13px' },
      sort: true,
      sortCaret:
        columnName === 'serial_number'
          ? CustomSortCaretUpdatedByOrder
          : CustomSortCaret,
      style: {
        fontSize: '12px',
        fontWeight: 'bold',
        color: 'phoenix-gray-1100'
      },
      formatter: (cell, row) => {
        return <>{row.user}</>;
      }
    },

    {
      dataField: 'status',
      text: 'STATUS',
      sort: true,
      sortCaret:
        columnName === 'status'
          ? CustomSortCaretUpdatedByOrder
          : CustomSortCaret,
      headerStyle: { fontSize: '13px' },
      style: {
        fontSize: '12px',
        color: 'phoenix-gray-1100'
      },
      formatter: (cell, row) => {
        if (
          (row.status && row.status.toLowerCase() === 'checked out') ||
          (row.status && row.status.toLowerCase() === 'in stock') ||
          (row.status && row.status.toLowerCase() === 'enrolled')
        ) {
          return (
            <Badge
              bg={'success'}
              variant="phoenix"
              iconPosition="end"
              className="fs-10"
              icon={<FeatherIcon icon={'check'} size={12} className="ms-1" />}
            >
              {row.status}
            </Badge>
          );
        } else {
          return (
            <Badge
              bg={'danger'}
              variant="phoenix"
              iconPosition="end"
              className="fs-10"
              icon={<FeatherIcon icon={'info'} size={12} className="ms-1" />}
            >
              {row.status}
            </Badge>
          );
        }
      }
    }
  ];

  const handleAllClick = () => {
    setSortString('id:desc');
    setCurrentPage(1);
    loadData(1, 10, 'id:desc');
  };

  const tabItems = [
    {
      label: 'All',
      value: 'all',
      count: total,
      onClick: handleAllClick
    }
  ];

  const table = useAdvanceTable({
    data: data
  });

  const handleTableChange = (
    type,
    { page, sizePerPage, sortField, sortOrder, data }
  ) => {
    switch (type) {
      case 'pagination':
        pagination(page, sizePerPage);
        break;
      case 'sort':
        sort(sortField, sortOrder, data);
        break;
      default:
        break;
    }
  };

  const pagination = (page, sizePerPage) => {
    setCurrentPage(page);
    setPageSize(sizePerPage);
    loadData(page, sizePerPage, sortString);
  };

  const sort = sortField => {
    setCurrentPage(1);
    if (sortColumn === 'desc') {
      setSortColumn('asc');
    } else if (sortColumn === 'asc') {
      setSortColumn('desc');
    }
    switch (sortField) {
      case 'added_time':
        setColumnName('added_time');
        setSortString(`added_time:${sortColumn}`);
        loadData(1, 10, `added_time:${sortColumn}`);
        break;
      case 'status':
        setColumnName('status');
        setSortString(`status.name:${sortColumn}`);
        loadData(1, 10, `status.name:${sortColumn}`);
        break;
      case 'user':
        setColumnName('user');
        setSortString(`user.first_name:${sortColumn}`);
        loadData(1, 10, `user.first_name:${sortColumn}`);
        break;

      default:
        break;
    }
  };

  return (
    <div>
      <PageBreadcrumb items={breadcrumbItems} />
      <div className="mb-9">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          {data.length > 0 && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <h2 className="mb-2">
                {`${data.length > 0 ? data[0].name : ''} / ${
                  data.length > 0 ? data[0].description : ''
                }`}
              </h2>
              <h5 className="text-700 fw-semi-bold">
                <strong>EPC:</strong>
                {data.length > 0 ? ` ${data[0].epc} ` : ''} -{' '}
                <strong>Serial No:</strong>
                {data.length > 0 ? ` ${data[0].serial_number} ` : ''}`
              </h5>
            </div>
          )}
          <img
            src={data.length > 0 && data[0].image}
            alt=""
            className="w-2"
            style={{ marginLeft: 'auto' }}
          />
        </div>

        <FilterTab tabItems={tabItems} className="mb-2" />

        <AdvanceTableProvider {...table}>
          <div>
            {Object.entries(error).length > 0 &&
              error.map((e, index) => (
                <Alert variant="soft-danger" key={index}>
                  {e}
                </Alert>
              ))}
          </div>

          <div className="bg-white border-top border-bottom border-200 position-relative top-1">
            {loading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: '200px' }}
              >
                <Spinner animation="border" role="status"></Spinner>
              </div>
            ) : (
              <PaginationProvider
                pagination={paginationFactory({
                  custom: true,
                  page: currentPage,
                  sizePerPage: pageSize,
                  totalSize: total,
                  sizePerPageList: [
                    {
                      text: '10',
                      value: 10
                    }
                  ]
                })}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <div>
                    <BootstrapTable
                      remote
                      keyField="id"
                      data={data.length === 0 ? [] : data}
                      columns={columns}
                      bordered={false}
                      onTableChange={handleTableChange}
                      {...paginationTableProps}
                    />
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          marginTop: '-10px',
                          fontSize: '13px'
                        }}
                      >
                        <PaginationTotalStandalone {...paginationProps} />
                        <PaginationListStandalone {...paginationProps} />
                      </div>
                    </div>
                  </div>
                )}
              </PaginationProvider>
            )}
          </div>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default StockLogs;
