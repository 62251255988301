import { useEffect, useState } from 'react';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { useNavigate, Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationTotalStandalone,
  PaginationProvider,
  PaginationListStandalone
} from 'react-bootstrap-table2-paginator';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Alert, Spinner, Dropdown, Button } from 'react-bootstrap';
import Badge from 'components/base/Badge';
import FilterTab from 'components/common/FilterTab';
import useAdvanceTable from 'hooks/useAdvanceTable';
import FeatherIcon from 'feather-icons-react';
import { CustomSortCaret } from 'helpers/ui-utils';
import { getUnauthorizedAccessLogs } from 'api/api';
import DownloadButton from 'helpers/download-utils-un-auth';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';

const WeaponAccessLogs = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [error, setError] = useState([]);
  const [total, setTotal] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [sortColumn, setSortColumn] = useState('asc');
  const [columnName, setColumnName] = useState('id');
  const [downLoadData, setDownLoadData] = useState([]);
  const [sortString, setSortString] = useState('id:desc');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [clearButtonVisibility, setClearButtonVisibility] = useState(false);
  const [statusId, setStatusId] = useState('');
  const [stats, setStats] = useState([]);

  useEffect(() => {
    loadData(currentPage, pageSize, sortString, null, null, null);
  }, []);

  useEffect(() => {
    loadDownLoadData();
  }, []);

  const loadData = (pageNr, pageSize, sortBy, statusId, fromDate, toDate) => {
    setError([]);
    setLoading(true);
    setData([]);
    setStats([]);

    fromDate = fromDate === null ? null : moment(fromDate).format('YYYY-MM-DD');
    toDate = toDate === null ? null : moment(toDate).format('YYYY-MM-DD');

    getUnauthorizedAccessLogs(
      pageNr,
      pageSize,
      sortBy,
      statusId,
      fromDate,
      toDate
    )
      .then(response => {
        setTotal(response.data.data.total);
        setStats(response.data.data.stats);
        if (response.data.data.data.length > 0) {
          const flattenedData = response.data.data.data.map(item => ({
            id: item.id,
            name: item.item.name,
            item_id: item.item.id,
            sku: item.item.sku,
            description: item.item.description,
            serial_number: item.item.stock.serial_number,
            category: item.item.category.name,
            parent_category: item.item.category.parent_category.name,
            epc: item.item.stock && item.item.stock.epc.slice(-8),
            location: item.item.container.location.name,
            container: item.item.container.name,
            status: item.status.name,
            image: item.item.image,
            stock_id: item.item.stock.id,
            access_by:
              item.user === null
                ? ''
                : `${item.user.first_name} ${item.user.last_name}`,
            added_time:
              item.added_time === null
                ? ''
                : moment(item.added_time).format('MM-DD-YYYY HH:mm')
          }));

          setData(flattenedData);
        }
      })
      .catch(err => {
        try {
          if (err.response.status === 401 || err.response.status === 400) {
            navigate('/sign-in');
          }
          setError(err.response.data.errors);
        } catch (ex) {
          setError(['Please try again later...']);
        }
      });

    setTimeout(function () {
      setLoading(false);
    }, 1000);
  };

  const loadDownLoadData = (statusId, startDate, endDate) => {
    setError([]);
    setDownLoadData([]);
    startDate =
      startDate === null ? null : moment(startDate).format('YYYY-MM-DD');
    endDate = endDate === null ? null : moment(endDate).format('YYYY-MM-DD');

    getUnauthorizedAccessLogs(1, 0, 'id:desc', statusId, startDate, endDate)
      .then(response => {
        if (response.data.data.data.length > 0) {
          const flattenedData = response.data.data.data.map(item => ({
            serial_number: item.item.stock.serial_number,
            make: item.item.name,
            model: item.item.description,
            category: item.item.category.name,
            calibre: item.item.sku,
            epc: item.item.stock && item.item.stock.epc.slice(-8),
            // location: item.container.location.name,
            container: item.item.container.name,
            status: item.status.name,
            access_by:
              item.user === null
                ? ''
                : `${item.user.first_name} ${item.user.last_name}`,
            time:
              item.added_time === null
                ? ''
                : moment(item.added_time).format('MM-DD-YYYY HH:mm')
          }));
          setDownLoadData(flattenedData);
        }
      })
      .catch(err => {
        try {
          if (err.response.status === 401 || err.response.status === 400) {
            navigate('/sign-in');
          }
          setError(err.response.data.errors);
        } catch (ex) {
          setError(['Please try again later...']);
        }
      });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const CustomSortCaretUpdatedByOrder = (order, dataField) => {
    if (columnName === 'id')
      return (
        <span className="custom-sort-cart">
          <div style={{ display: 'inline-block' }}>
            ▲
            <br />▼
          </div>
        </span>
      );
    else if (sortColumn === 'asc')
      return (
        <span className="custom-sort-cart">
          <div style={{ display: 'inline-block' }}>
            <div style={{ display: 'inline-block' }}> ▲ </div>
            <br />
            <div style={{ display: 'inline-block', visibility: 'collapse' }}>
              ▼
            </div>
          </div>
        </span>
      );
    else if (sortColumn === 'desc')
      return <span className="custom-sort-cart">▼</span>;
    return null;
  };

  const columns = [
    {
      dataField: 'image',
      formatter: (cellContent, row) => (
        <Link
          to={`/weapons/weapon-details/${row.item_id}`}
          className="rounded-2 border d-inline-block"
        >
          <img src={row.image} alt="" width={53} />
        </Link>
      ),
      headerStyle: () => ({ width: '80px' })
    },

    {
      dataField: 'serial_number',
      text: 'SERIAL NO',
      headerStyle: { width: '15%', fontSize: '13px' },
      sort: true,
      sortCaret:
        columnName === 'serial_number'
          ? CustomSortCaretUpdatedByOrder
          : CustomSortCaret,
      style: {
        fontSize: '12px',
        fontWeight: 'bold',
        color: 'phoenix-gray-1100'
      },
      formatter: (cell, row) => {
        return <>{row.serial_number}</>;
      }
    },

    {
      dataField: 'name',
      text: 'MAKE',
      headerStyle: { width: '15%', fontSize: '13px' },
      sort: true,
      sortCaret:
        columnName === 'name' ? CustomSortCaretUpdatedByOrder : CustomSortCaret,
      style: {
        fontSize: '12px',
        fontWeight: 'bold',
        color: 'phoenix-gray-1100'
      },
      formatter: (cell, row) => {
        return (
          <>
            <Link
              to={`/weapons/weapon-details/${row.item_id}`}
              className="fw-semi-bold line-clamp-3"
            >
              {row.name}
            </Link>
          </>
        );
      }
    },
    {
      dataField: 'description',
      text: 'MODEL',
      headerStyle: { width: '10%', fontSize: '13px' },
      sort: true,
      sortCaret:
        columnName === 'description'
          ? CustomSortCaretUpdatedByOrder
          : CustomSortCaret,
      style: {
        fontSize: '12px',
        color: 'phoenix-gray-1100'
      },
      formatter: (cell, row) => {
        return <> {row.description}</>;
      }
    },
    {
      dataField: 'category',
      text: 'CATEGORY',
      headerStyle: { width: '10%', fontSize: '13px' },
      sort: true,
      sortCaret:
        columnName === 'category'
          ? CustomSortCaretUpdatedByOrder
          : CustomSortCaret,
      style: {
        fontSize: '12px',
        color: 'phoenix-gray-1100'
      },
      formatter: (cell, row) => {
        return <> {row.category}</>;
      }
    },

    {
      dataField: 'sku',
      text: 'CALIBRE',
      headerStyle: { width: '10%', fontSize: '13px' },
      sort: true,
      sortCaret:
        columnName === 'sku' ? CustomSortCaretUpdatedByOrder : CustomSortCaret,
      style: {
        fontSize: '12px',
        fontWeight: 'bold',
        color: 'phoenix-gray-1100'
      },
      formatter: (cell, row) => {
        return <>{row.sku}</>;
      }
    },
    {
      dataField: 'epc',
      text: 'EPC',
      headerStyle: { width: '10%', fontSize: '13px' },
      sort: true,
      sortCaret:
        columnName === 'epc' ? CustomSortCaretUpdatedByOrder : CustomSortCaret,
      style: {
        fontSize: '12px',
        fontWeight: 'bold',
        color: 'phoenix-gray-1100'
      }
    },
    {
      dataField: 'access_by',
      text: 'ACCESS BY',
      headerStyle: { width: '15%', fontSize: '13px' },
      sort: true,
      sortCaret:
        columnName === 'access_by'
          ? CustomSortCaretUpdatedByOrder
          : CustomSortCaret,
      style: {
        fontSize: '12px',
        fontWeight: 'bold',
        color: 'phoenix-gray-1100'
      },
      formatter: (cell, row) => {
        return <>{row.access_by}</>;
      }
    },

    {
      dataField: 'added_time',
      text: 'TIME',
      headerStyle: { width: '15%', fontSize: '13px' },
      sort: true,
      sortCaret:
        columnName === 'added_time'
          ? CustomSortCaretUpdatedByOrder
          : CustomSortCaret,
      style: {
        fontSize: '12px',
        fontWeight: 'bold',
        color: 'phoenix-gray-1100'
      },
      formatter: (cell, row) => {
        return <>{row.added_time}</>;
      }
    },

    {
      dataField: 'status',
      text: 'STATUS',
      sort: true,
      sortCaret:
        columnName === 'status'
          ? CustomSortCaretUpdatedByOrder
          : CustomSortCaret,
      headerStyle: { fontSize: '13px' },
      style: {
        fontSize: '12px',
        color: 'phoenix-gray-1100'
      },
      formatter: (cell, row) => {
        if (
          (row.status && row.status.toLowerCase() === 'checked out') ||
          (row.status && row.status.toLowerCase() === 'in stock') ||
          row.status.toLowerCase() === 'enrolled'
        ) {
          return (
            <Badge
              bg={'success'}
              variant="phoenix"
              iconPosition="end"
              className="fs-10"
              icon={<FeatherIcon icon={'check'} size={12} className="ms-1" />}
            >
              {row.status}
            </Badge>
          );
        } else {
          return (
            <Badge
              bg={'danger'}
              variant="phoenix"
              iconPosition="end"
              className="fs-10"
              icon={<FeatherIcon icon={'info'} size={12} className="ms-1" />}
            >
              {row.status}
            </Badge>
          );
        }
      }
    },

    {
      dataField: 'actions',
      formatter: (cell, row) => (
        <div className="table-action-button">
          <RevealDropdownTrigger>
            <RevealDropdown onSelect={eventKey => handleSelect(row, eventKey)}>
              <Dropdown.Item eventKey={`logs:${row.id}`}>Logs</Dropdown.Item>
            </RevealDropdown>
          </RevealDropdownTrigger>
        </div>
      )
    }
  ];

  const handleSelect = (row, eventKey) => {
    const parts = eventKey.split(':');
    if (parts[0] === 'logs') {
      navigate(
        `/weapons/stock-weapon/stock-logs/${row.item_id}/${row.stock_id}`,
        {
          state: { customState: 'weaponAccessLogs' }
        }
      );
    }
  };

  const handleAllClick = id => {
    setCurrentPage(1);
    setStatusId(id);
    if (id === 'all') {
      loadData(1, 10, 'id:desc', '', startDate, endDate);
      loadDownLoadData('', startDate, endDate);
    } else {
      loadData(1, 10, 'id:desc', id, startDate, endDate);
      loadDownLoadData(id, startDate, endDate);
    }
  };

  const tabItems =
    stats &&
    stats
      .map(stat => ({
        label: stat.status ? stat.status.name : 'All',
        value: stat.status ? stat.status.id : 'all',
        count: stat.total,
        onClick: () => handleAllClick(stat.status ? stat.status.id : 'all')
      }))
      .sort((a, b) =>
        a.label === 'All' ? -1 : a.label.localeCompare(b.label)
      );

  const table = useAdvanceTable({
    data: data
  });

  const handleTableChange = (
    type,
    { page, sizePerPage, sortField, sortOrder, data }
  ) => {
    switch (type) {
      case 'pagination':
        pagination(page, sizePerPage);
        break;
      case 'sort':
        sort(sortField, sortOrder, data);
        break;
      default:
        break;
    }
  };

  const pagination = (page, sizePerPage) => {
    setCurrentPage(page);
    setPageSize(sizePerPage);
    loadData(page, sizePerPage, sortString, statusId, startDate, endDate);
  };

  const handleLoadData = () => {
    setCurrentPage(1);
    setClearButtonVisibility(true);

    loadData(
      1,
      10,
      'id:desc',
      statusId,
      startDate === null ? null : moment(startDate).format('YYYY-MM-DD'),
      endDate === null ? null : moment(endDate).format('YYYY-MM-DD')
    );

    loadDownLoadData(
      statusId,
      startDate === null ? null : moment(startDate).format('YYYY-MM-DD'),
      endDate === null ? null : moment(endDate).format('YYYY-MM-DD')
    );
  };

  const handleClearFilters = () => {
    setCurrentPage(1);
    setStartDate(null);
    setEndDate(null);
    setClearButtonVisibility(false);
    loadData(currentPage, pageSize, 'id:desc', null, null, null);
    loadDownLoadData(null, null);
  };

  const sort = sortField => {
    setCurrentPage(1);
    if (sortColumn === 'desc') {
      setSortColumn('asc');
    } else if (sortColumn === 'asc') {
      setSortColumn('desc');
    }
    switch (sortField) {
      case 'name':
        setColumnName('name');
        setSortString(`item_stock.item.name:${sortColumn}`);
        loadData(
          1,
          10,
          `item_stock.item.name:${sortColumn}`,
          statusId,
          startDate,
          endDate
        );
        break;
      case 'sku':
        setColumnName('sku');
        setSortString(`item_stock.item.sku:${sortColumn}`);
        loadData(
          1,
          10,
          `item_stock.item.sku:${sortColumn}`,
          statusId,
          startDate,
          endDate
        );
        break;
      case 'description':
        setColumnName('description');
        setSortString(`item_stock.item.description:${sortColumn}`);
        loadData(
          1,
          10,
          `item_stock.item.description:${sortColumn}`,
          statusId,
          startDate,
          endDate
        );
        break;
      case 'epc':
        setColumnName('epc');
        setSortString(`item_stock.epc:${sortColumn}`);
        loadData(
          1,
          10,
          `item_stock.epc:${sortColumn}`,
          statusId,
          startDate,
          endDate
        );
        break;
      case 'serial_number':
        setColumnName('serial_number');
        setSortString(`item_stock.serial_number:${sortColumn}`);
        loadData(
          1,
          10,
          `item_stock.serial_number:${sortColumn}`,
          statusId,
          startDate,
          endDate
        );
        break;
      case 'parent_category':
        setColumnName('parent_category');
        setSortString(
          `item_stock.item.item_category.item_category.name:${sortColumn}`
        );
        loadData(
          1,
          10,
          `item_stock.item.item_category.item_category.name:${sortColumn}`,
          statusId,
          startDate,
          endDate
        );
        break;
      case 'category':
        setColumnName('category');
        setSortString(`item_stock.item.item_category.name:${sortColumn}`);
        loadData(
          1,
          10,
          `item_stock.item.item_category.name:${sortColumn}`,
          statusId,
          startDate,
          endDate
        );
        break;
      case 'location':
        setColumnName('location');
        setSortString(`item_stock.item.container.location.name:${sortColumn}`);
        loadData(
          1,
          10,
          `item_stock.item.container.location.name:${sortColumn}`,
          statusId,
          startDate,
          endDate
        );
        break;
      case 'container':
        setColumnName('container');
        setSortString(`item_stock.item.container.name:${sortColumn}`);
        loadData(
          1,
          10,
          `item_stock.item.container.name:${sortColumn}`,
          statusId,
          startDate,
          endDate
        );
        break;
      case 'added_time':
        setColumnName('added_time');
        setSortString(`added_time:${sortColumn}`);
        loadData(
          1,
          10,
          `added_time:${sortColumn}`,
          statusId,
          startDate,
          endDate
        );
        break;
      case 'status':
        setColumnName('status');
        setSortString(`status.name:${sortColumn}`);
        loadData(
          1,
          10,
          `status.name:${sortColumn}`,
          statusId,
          startDate,
          endDate
        );
        break;
      case 'access_by':
        setColumnName('access_by');
        setSortString(`user.first_name:${sortColumn}`);
        loadData(
          1,
          10,
          `user.first_name:${sortColumn}`,
          statusId,
          startDate,
          endDate
        );
        break;

      default:
        break;
    }
  };

  return (
    <div>
      <div className="mb-9">
        <div>
          <h2 className="mb-2">Weapon Access Logs</h2>
          <h5 className="text-700 fw-semi-bold">
            {`Weapon access logs are shown below.`}
          </h5>
        </div>

        <FilterTab tabItems={tabItems} className="mb-2" />

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <div className="d-flex flex-wrap gap-3">
              <div className="scrollbar overflow-hidden-y">
                <DatePicker
                  selected={startDate}
                  onChange={date => setStartDate(date)}
                  placeholder="Start Date"
                  className="form-control"
                  placeholderText="Select start date"
                  dateFormat="MM-dd-yyyy"
                />
              </div>

              <div className="scrollbar overflow-hidden-y">
                <DatePicker
                  selected={endDate}
                  onChange={date => setEndDate(date)}
                  className="form-control"
                  placeholderText="Select end date"
                  dateFormat="MM-dd-yyyy"
                />
              </div>

              <div className="scrollbar overflow-hidden-y">
                <Button
                  variant="primary"
                  type="button"
                  onClick={() => handleLoadData()}
                >
                  Load Data
                </Button>
              </div>

              <div className="scrollbar overflow-hidden-y">
                <Button
                  variant="phoenix-secondary"
                  style={{
                    visibility:
                      clearButtonVisibility === true ? 'visible' : 'hidden'
                  }}
                  onClick={() => handleClearFilters()}
                >
                  Clear Filters
                </Button>
              </div>
              <div className="ms-xxl-auto">
                <DownloadButton
                  data={downLoadData}
                  info={`Weapon access logs are shown below.`}
                  allCount={total}
                  title="Weapon Access Logs"
                  savePath="WeaponAccessLogs"
                />
              </div>
            </div>
          </div>

          <div>
            {Object.entries(error).length > 0 &&
              error.map((e, index) => (
                <Alert variant="soft-danger" key={index}>
                  {e}
                </Alert>
              ))}
          </div>

          <div className="bg-white border-top border-bottom border-200 position-relative top-1">
            {loading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: '200px' }}
              >
                <Spinner animation="border" role="status"></Spinner>
              </div>
            ) : (
              <PaginationProvider
                pagination={paginationFactory({
                  custom: true,
                  page: currentPage,
                  sizePerPage: pageSize,
                  totalSize: total,
                  sizePerPageList: [
                    {
                      text: '10',
                      value: 10
                    }
                  ]
                })}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <div>
                    <BootstrapTable
                      remote
                      keyField="id"
                      data={data.length === 0 ? [] : data}
                      columns={columns}
                      bordered={false}
                      onTableChange={handleTableChange}
                      {...paginationTableProps}
                    />
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          marginTop: '-10px',
                          fontSize: '13px'
                        }}
                      >
                        <PaginationTotalStandalone {...paginationProps} />
                        <PaginationListStandalone {...paginationProps} />
                      </div>
                    </div>
                  </div>
                )}
              </PaginationProvider>
            )}
          </div>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default WeaponAccessLogs;
